.request-vacation-type-title {
    color: #cfcfcf !important;
}

.request-vacation-type-select {
    color: #ffffff !important;
    background-color: #2f2f2f !important;
}

.request-vacation-form {
    width: 100%;
}

.request-vacation-form .MuiSelect-select {
    color: #fff;
}

.request-vacation-disabled .MuiInputBase-input.Mui-disabled {
    -webkit-text-fill-color: white !important;
    cursor: not-allowed;
}

.request-vacation-date-picker {
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.request-vacation-info-box {
    background-color: #2f2f2f;
    padding: 1rem;
    border-radius: 8px;
    margin-top: 16px;
    min-height: 143px;
    align-self: flex-start;
    flex-grow: 1;
}

.request-vacation-info-title {
    color: #cfcfcf !important;
    display: flex !important;
    justify-content: center !important;
    margin-bottom: 10px !important;
}

.request-vacation-total-count {
    color: #cfcfcf !important;
    margin-top: 1rem !important;
}

.request-vacation-error {
    color: red !important;
    margin-top: 8px !important;
}

.request-vacation-flex {
    display: flex;
    justify-content: space-between;
}

.request-vacation-admins-info-box {
    border: 1px dashed #cfcfcf;
    border-radius: 8px;
    padding: 16px;
    margin-top: 8px;
}

.request-vacation-buttons-box {
    margin-top: 16px;
}

.request-vacation-cancel-button {
    margin-left: 8px !important;
}

.request-vacation-main-style {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 600px;
    background-color: #1c1c1c;
    border: 2px solid #2f2f2f;
    box-shadow: 0 0 24px rgba(0, 0, 0, 0.2);
    padding: 32px;
    max-height: 100vh;
    overflow-y: auto;
}

.request-vacation-date-info {
    display: flex;
    gap: 1rem;
}

.request-vacation-total-days {
    display: flex;
    flex-direction: column;
}

@media (max-width: 1000px) {
    .request-vacation-main-style {
        width: 85%;
    }
}

@media (max-width: 850px) {
    .request-vacation-date-info {
        flex-direction: column;
    }

    .request-vacation-date-picker,
    .request-vacation-info-box {
        width: 100%;
        margin: 0;
    }

    .MuiTextField-root {
        width: 100%; /* Not sure if that breaks something somewhere else? */
    }
}

@media (max-width: 500px) {
    .request-vacation-flex {
        flex-direction: column;
    }
}