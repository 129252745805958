.create-event-main-style {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 700px;
    background-color: #1c1c1c;
    border-radius: 10px;
    box-shadow: 0 4px 24px rgba(0, 0, 0, 0.1);
    padding: 32px;
    max-height: 90vh;
    overflow-y: auto;
    color: #C0C0C0;
}

.create-event-title {
    color: #C0C0C0 !important;
}

.create-event-error {
    margin-bottom: 16px !important;
}

.create-event-title-text {
    background-color: #333 !important;
    color: #fff !important;
    width: 100% !important;
}

.create-event-search-field {
    margin-bottom: 16px !important;
}

.create-event-collaborators-box {
    max-height: 200px !important;
    overflow-y: auto !important;
    margin-bottom: 16px !important;
}

.create-event-collab-list-default {
    background-color: #333 !important;
}

.create-event-collab-list-selected {
    background-color: #424242 !important;
}

.create-event-remove-collab-icon {
    color: red !important;
}

.create-event-add-collab-icon {
    color: green !important;
}

@media (max-width: 1000px) {
    .create-event-main-style {
        width: 80%
    }
}