.workspace-content-members {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center
}

.owner-img-members {
    width: auto;
    max-height: 100px;
}

.workspace-title-members {
    text-align: center;
}

.workspace-title-txt-members {
    color: #C0C0C0 !important;
    margin-left: 10px !important;
    overflow-wrap: anywhere;
}

.workspace-subtitle-txt-members {
    color: #C0C0C0 !important;
    margin-left: 10px !important;
    font-weight: lighter !important;
    overflow-wrap: anywhere;
}

.divider-members {
    background-color: #A3989E !important;
    margin-top: 50px !important;
    margin-bottom: 50px !important;
}

.table-members {
    display: flex;
    flex-direction: column;
    align-items: center
}

.box-members {
    width: 90%;
}

.search-members {
    margin-bottom: 16px !important;
    width: 100%;
}

.search-members .MuiInputBase-input {
    color: white !important;
}

.table-paper-members {
    margin-bottom: 16px;
}

.table-size-members {
    width: 100%;
    table-layout: auto;
}

.table-cell-members {
    background-color: #f5f5f9;
    word-wrap: break-word;
    white-space: normal;
}

.table-title-members {
    color: black;
}

.table-info-icon-members {
    color: black;
    margin-left: 10px;
}

.table-cursor-members {
    cursor: pointer !important;
}

.table-pagination-members .MuiTablePagination-selectLabel {
    align-self: flex-end !important;
}

.table-pagination-members .MuiTablePagination-displayedRows {
    align-self: flex-end !important;
}

.buttons-flex-rows-members {
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    margin: 16px 0;
}

.buttons-flex-columns-members {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 16px 0;
}

.remove-button-members {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 16px 0 16px 40px;
}

.remove-button-remove-yourself {
    display: flex !important; 
    justify-content: center !important;
    
    margin-top: 16px !important; 
    
}
.remove_yourself{
    
    margin-bottom: 40px !important; 
    
}


.custom-tooltip-members {
    background-color: #252224 !important;
    color: #FFF !important;
    max-width: 400px !important;
    font-size: 12px !important;
    border: 1px solid #dadde9 !important;
}

.promote-button-width-members {
    width: 100%; /* Full width, can adjust as needed */
    max-width: 177px; /* Maximum width on larger screens */
    padding: 10px 20px; /* Adjust padding for better appearance */
}

.remove-button-width-members {
    width: 100%; /* Full width, can adjust as needed */
    max-width: 177px; /* Maximum width on larger screens */
    height: auto; /* Remove fixed height, use padding instead */
    padding: 15px 20px; /* Control size with padding */
}

.button-span-members {
    width: 100%;
}

@media (max-width: 850px) {
    .workspace-content-members {
        flex-direction: column;
    }
}

@media (max-width: 600px) {
    .promote-button-width-members,
    .remove-button-width-members {
        max-width: none; /* Remove max-width limitation */
        padding: 10px; /* Adjust padding for smaller screens */
    }

    .buttons-flex-rows-members {
        justify-content: space-around;
    }
}

/* For very small screens (mobile) */
@media (max-width: 400px) {
    .promote-button-width-members,
    .remove-button-width-members {
        width: 100%; /* Full width for smaller screens */
        padding: 8px; /* Smaller padding */
    }
}