.sortable-project-landing-page {
    min-width: 350px !important;
    max-width: 350px !important;
    max-height: 377px !important;
    margin: 25px 5% 25px !important;
    padding: 0 !important;
}

.sortable-project-landing-page.isDragging {
    touch-action: none !important;
}

.workspace-image-landing-page {
    max-width: 150px;
    height: 150px;
    width: auto;
    max-height: 100px;
}

.centered-box-landing-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    width: 100%;
    max-width: 750px;
}

.workspace-title-landing-page {
    color: #C0C0C0 !important;
    padding-left: 10%;
    text-align: center;
    overflow-wrap: anywhere;
}

.workspace-subtitle-landing-page {
    color: #C0C0C0 !important;
    font-weight: lighter;
    padding-left: 10%;
    overflow-wrap: anywhere;
}

.divider-landing-page {
    background-color: #A3989E !important;
    margin-bottom: 20px !important;
}

.user-input-options-landing-page {
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.form-control-landing-page {
    background-color: #333;
    color: #fff !important;
    width: 100%;
}

.select-menu-landing-page {
    background-color: #333 !important;
    color: #fff !important;
}

.form-control-landing-page .MuiInputLabel-root.Mui-focused {
    color: #0063B2 !important;
}

.form-control-landing-page .MuiSelect-select {
    color: #fff !important;
}

.main-box-landing-page {
    align-items: center;
    margin-left: 35px;
    margin-right: 35px;
}

.title-flex-box-landing-page {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

.text-input-landing-page {
    background-color: #333;
    color: #fff;
    width: 100%;
}

.text-input-landing-page .MuiInputLabel-root, .text-input-landing-page .MuiInputBase-input {
    color: #fff;
}

.form-control-label-landing-page {
    display: flex;
    justify-content: center;
    margin-bottom: 0;
}

.form-control-label-favs-landing-page {
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    margin-bottom: 2px;
}


.pagination-landing-page {
    display: flex;
    justify-content: center;
    color: #FFF;
    align-items: center;
    align-content: center;
}

.pagination-landing-page .MuiPaginationItem-root {
    color: #C0C0C0;
}

.warning-box-landing-page {
    display: flex;
    justify-content: center;
    margin-bottom: 50px;
    color: #FFA000;
}

.no-matches-landing-page {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
    color: #C0C0C0;
}

.warning-icon-left-landing-page {
    margin-right: 15px;
    color: #FFC107;
}

.warning-icon-right-landing-page {
    margin-left: 15px;
    color: #FFC107;
}

.sortable-items-landing-page {
    margin-bottom: 50px;
    margin-top: 40px;
    display: flex;
    justify-content: center !important;
    width: 100%;
}

.draggable-items-landing-page {
    justify-content: center;
    margin-left: 0 !important;
}

@media (max-width: 800px) {
    .title-flex-box-landing-page{
        flex-direction: column;
    }
    .image-margin-landing-page {
        margin-right: 0;
    }

    .workspace-title-landing-page {
        padding-left: 0;
    }

    .workspace-subtitle-landing-page {
        padding-left: 0;
    }

    .MuiTypography-h3 {
        font-size: 2.5rem !important;
    }

}

@media (max-width: 500px) {
    .sortable-project-landing-page {
        min-width: 100% !important;
    }
}

@media (max-width: 1000px) {
    .centered-box-landing-page {
        width: 75%;
    }
}
