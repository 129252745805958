.form-details {
    min-width: 200px !important;
}

.input-label-details, .props-details .MuiMenuItem-root, .input-label-details.Mui-focused {
    color: #FFF !important;
}

.select-details {
    color: #000 !important;
    width: 200px !important;
}

.form-details .MuiOutlinedInput-notchedOutline,
.form-details:hover .MuiOutlinedInput-notchedOutline,
.form-details.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: black !important;
}

.form-details .MuiSvgIcon-root {
    color: black !important;
}

/* Background colors for different status */
.status-completed-details {
    background-color: #228B22 !important; /* Green */
}

.status-ongoing-details {
    background-color: #1E90FF !important; /* Blue */
}

.status-archived-details {
    background-color: #A3A3A3 !important; /* Gray */
}

.status-not-started-details {
    background-color: #FF8C00 !important; /* Orange */
}

.props-details {
    background-color: #333 !important;
}

.visibility-true-details {
    background-color: #90EE90 !important; /* Light green */
}

.visibility-false-details {
    background-color: #FFB6C1 !important; /* Light pink */
}

.save-status-button-details {
    height: 56px;
    background-color: #333 !important;
}

.resource-card-details {
    background-color: #252224 !important;
}

.add-resource-icon-details {
    font-size: large;
    color: #D6D4D4;
}

.card-description-details {
    margin-bottom: 1rem !important;
}

.delete-btn-details {
    margin-right: 0 !important;
    margin-left: auto !important;
}

.delete-resource-btn-details {
    color: #f44336 !important;
}

.toggle-secret-btn-details {
    margin-right: 0 !important;
    margin-left: auto !important;
    color: white !important;
}

.secrets-delete-btn-error-details {
    color: #f44336 !important;
}

.secrets-delete-btn-disabled-details {
    color: #00000042 !important;
}

.snackbar-details .MuiSnackbarContent-root {
    background-color: white;
    color: black;
}

.div-row-details {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    gap: 20px;
}

.div-cards-details {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 20px;
    padding: 20px;
}

.div-resource-card-details {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.add-button-color-details {
    color: burlywood !important;
}

.div-secret-section-details {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.copy-text-details {
    color: white;
    cursor: default;
}

.copy-text-pointer-details {
    cursor: pointer;
}

.see-secret-color-details {
    color: #5e72e4;
}

.cloud-storage-icon-details {
    margin-right: 0;
    margin-left: auto;
}

.card-title-details {
    font-size: 1.5vw !important;
}

.file-link {
    cursor:pointer !important;
}


@media(max-width: 1000px) {
    .card-title-details {
        font-size: 2.25vw !important;
    }

    .button-text-details {
        display: none;
    }

    .add-resource-icon-details {
        font-size: 2rem !important;
    }

    .div-cards-details {
        display: flex;
        flex-direction: column;
    }

    .div-row-details {
        flex-direction: column;
    }
    

}