.create-task-dialog-content {
    max-height: 80vh;
    overflow: auto;
}

.create-task-pasted-cover {
    margin-top: 16px;
    background-color: #333;
    border-radius: 8px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.create-task-cover-caption {
    margin-top: 8px !important;
}

.create-task-labels {
    display: flex;
    flex-direction: row;
}

.create-task-label-margin {
    margin-right: 8px !important;
}

.create-task-dialog-actions {
    justify-content: space-between !important;
}

.create-task-actions-box {
    margin: 16px;
}

.create-task-label-border {
    color: white !important;
    border-color: white !important;
}

.create-task-label-list {
    background-color: #252224;
    color: white;
}

.create-task-label-item:hover {
    background-color: #3a393b !important;
}

.create-task-create-label-margin {
    margin-left: 5px !important;

}

.create-task-cancel-button {
    margin-right: 8px !important;
}

.create-task-label-dialog .MuiPaper-root {
    background: #252224;
    display: flex;
    text-align: -webkit-center;
}

.create-task-label-dialog-content {
    width: 400px;
}

.create-task-add-label {
    display: flex;
    justify-content: space-between;
}

.create-task-pasted-cover-img {
    max-width: 100%;
    max-height: 200px;
}

.create-task-label-checkboxes {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}

.create-task-label-div {
    width: 225px;
    height: 30px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    padding: 0 10px;
    justify-content: space-between;
    cursor: pointer;
    border: none;
}

.create-task-label-div-selected {
    border: 2px solid white;
}

.create-task-label-title-input {
    font-weight: 400 !important;
    font-size: 20px !important;
    line-height: 1.334 !important;
    color: #C0C0C0 !important;
}

.create-task-label-color-input {
    margin-top: 15px !important;
}

.create-task-label-color {
    margin-top: 15px;
    margin-bottom: 20px;
    width: 100%;
    height: 150px;
}

.create-task-label-default-colors {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 5px;
    margin-top: 5px;
}

.create-task-label-set-color {
    width: 100%;
    height: 30px;
    cursor: pointer;
    border-radius: 10px;
}

@media (max-width: 600px) {
    .create-task-dialog-actions {
        flex-direction: column;
    }
}