  /* General Styles */
  ::-webkit-scrollbar {
    width: 14px;
  }
  
  ::-webkit-scrollbar-track {
    background: rgb(171, 172, 172);
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: rgb(94, 95, 95);
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background-color: rgb(34, 34, 34);
  }


  .outer-landing-page-div {
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
    color: #333;
    width: 100%;
  }

  .rainbow-line {
    width: 10%; 
    height: 4px; 
    background: linear-gradient(to right, #ff7043, #ffab00, #00c853, #526dfd);
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2rem;
    user-select: none;
    z-index: 1;
}

  /* Header */
 .outer-landing-page-header {
    background-color: transparent;
    margin-left: 10%;
    margin-right: 10%;
    z-index: 0;
  }
  .outer-landing-page-nav {
    display: flex;
    justify-content: space-between; 
    align-items: center;
  }
  /* Logo Styling */
  .outer-landing-page-logo {
    height: 75px;
    user-select: none;
    cursor: pointer;
      z-index: 3;
  }
  /* Navigation List */
  .outer-landing-page-nav-list {
    display: flex;
    list-style: none;
    margin-right: 0; /* Align to the right */
    margin-bottom: 0;
      flex-wrap: wrap;
  }

  .outer-landing-page-nav-list.show {
      display: flex;
  }

  .outer-landing-page-nav-list.hide {
      display: none;
  }



  /* Navigation Links */
  .outer-landing-page-nav-link {
    margin: 0 1rem;
    text-decoration: none;
    color: #fff;
    transition: color 0.3s ease;
    user-select: none;
    cursor: pointer;
    font-family: 'Poppins', sans-serif;
    font-weight: normal;
  }
  .outer-landing-page-nav-link:hover {
    color: #ccc; /* Change color on hover */
  }
  
  /* Hero Section */
  .outer-landing-page-hero {
    background-size: cover;
    background-position: center; 
    background-repeat: no-repeat; 
    color: white;
    text-align: center;
    overflow: visible; 
    height: 120vh;
    z-index: 1;
  }
  
  .outer-landing-page-hero-content {
    display: flex;
    flex-direction: column;
    width: 60%;
    margin-top: 5rem;
    margin-left: 10%;
    padding: 5rem;
  }

  .outer-landing-page-hero-title {
      font-size: 2.5vw;
      margin-bottom: 1rem;
      text-align: left;
      color: white;
      user-select: none;
      font-family: 'Poppins', sans-serif;
      font-weight: normal;
      max-width: 100%; /* Prevents overflow */
  }

  .white-line {
    width: 20%; 
    height: 4px; 
    background: white;
    margin-bottom: 2rem;
    user-select: none;
  }

  .outer-landing-page-hero-description {
      font-size: 1.25vw;
      text-align: left;
      user-select: none;
      font-family: 'Poppins', sans-serif;
      font-weight: normal;
      max-width: 100%;
  }
  
  .outer-landing-page-hero-button {
    width: 30%;
    background-color: rgba(58, 149, 188, 0.5);
    color: white;
    padding: 2rem 2rem;
    font-size: 1rem;
    cursor: pointer;
    transition: background 0.3s ease;
    border: 1px solid white;
    user-select: none;
    font-family: 'Myriad Pro', sans-serif;
    font-weight: normal;
  }
  
  .outer-landing-page-hero-button:hover {
    background-color: white;
    color: rgba(58, 149, 188, 1);
    border: 1px solid rgba(58, 149, 188, 1);
  }

  .outer-landing-page-hero-features-title{
    font-size: 8vw;
    color: white;
    position: absolute;
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%) translateY(100%);
    user-select: none;
    font-family: 'Poppins', sans-serif;
    font-weight: medium;
      max-width: 100%;
  }

  .outer-landing-page-scroll-bar {
    position: absolute;
    bottom: 5rem;
    width: 100%;
    padding-left: 10%;
    padding-right: 10%;
    display: flex;
    align-items: center; /* Aligns content vertically */
    justify-content: space-between; /* Ensures buttons are on opposite sides */
  }

  
  
  .scroll-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
  }
  
  .triangle-scrollDown{
    width: 2rem;
    user-select: none;
  }
  
  
  .scroll-line {
    flex-grow: 1; 
    height: 2px;
    background-color: white;
    margin: 0 1rem; 
  }
  
  .right .triangle {
    transform: rotate(180deg); 
  }
  


  
  /* Features Section */
  .outer-landing-page-features {
    background-color: white;
    padding: 1rem 0;
    text-align: center;
  }
  
  .outer-landing-page-feature-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    transform: translateY(-15%);
    grid-auto-rows: 1fr;
  }
  
  .outer-landing-page-feature-item {
    background-color: #fff;
    padding: 3rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: left;
    text-align: left;
    cursor: pointer;
    position: relative;
    height: 100%;
  }

  .feature-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
  }
  
  .feature-symbol {
    width: 30px;
    height: 30px;
    user-select: none;
  }
  
  .feature-title {
    font-size: 1.25vw;
    margin-left: 1rem;
    margin-bottom: 0.5rem;
    color:white;
    user-select: none;
    font-family: 'Poppins', sans-serif;
    font-weight: 'normal';
  }
  
  .feature-description {
    font-size: 0.8vw;
    color:white;
    margin-left: 3rem;
    text-align: justify;
    user-select: none;
    font-family: 'Poppins', sans-serif;
    font-weight: 'normal';
  }

  .selected-feature-corner-decor {
    position: absolute;
    top: 1rem;
    left: 1rem;
    width: 0;
    height: 0;
    border-left: 1rem solid white; 
    border-top: 1rem solid white;
    border-bottom: 1rem solid transparent;
    border-right: 1rem solid transparent;
    pointer-events: none;
  }
  
  .outer-landing-page-feature-animation{
    width: 50%;
  }
  
  
  /* Benefits Section */
  .outer-landing-page-benefits {
    background-color: white;
    color: #90a4ae;
    text-align: center;
    padding: 2rem 0;
  }

  .outer-landing-page-section-title{
    color: #90a4ae;
    font-size: 3.25vw;
    user-select: none;
    font-family: 'Poppins', sans-serif;
    font-weight: normal;
    z-index: 1;
  }

  .outer-landing-page-benefits-text{
    width: 50%;
    font-size: 1rem;
    color: rgba(136, 165, 176, 0.75);
    margin-left: auto;
    margin-right: auto;
    text-align: justify;
    text-align-last: center;
    user-select: none;
    font-family: 'Poppins', sans-serif;
    font-weight: 'normal';
  }
  
  .outer-landing-page-testimonial {
    font-style: italic;
  }
  
  .outer-landing-page-testimonial-source {
    margin-top: 1rem;
    font-size: 1rem;
  }

  /* Testimony section*/
  .outer-landing-page-testimony{
    background-color: blue;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 50vh;
    width: 100%;
    overflow: visible;
  }


  .testimony-content {
    position: relative;
    z-index: 1;
    width: 30%;
    height: 100%;
    margin-left: 20vw;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: visible;
  }
  


  .testimony-background{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 90%;
    opacity: 0.3;
    z-index: -1;
    overflow: visible;
  }
  
  .testimony-company-header{
    display: flex;
    flex-direction: row;
    text-align: left;
    width: 100%;
    overflow: visible;
    user-select: none;
  }

  .testimony-image{
    max-height: 40px;
  }

  .company-name{
    font-size: 1rem;
    color: white;
    margin-left: 1rem;
    font-family: 'Poppins', sans-serif;
    font-weight: normal;
    line-height: 40px;
    user-select: none;
  }

  .testimony-title{
    font-size: 1.75vw;
    color: white;
    font-family: 'Poppins', sans-serif;
    font-weight: normal;
    user-select: none;
  }

  .testimony-text{
    font-size: 0.8vw;
    color: white;
    font-family: 'Poppins', sans-serif;
    font-weight: normal;
    text-align: justify;
    user-select: none;
  }
  

  .testimony-buttons {
    display: flex;
    justify-content: space-between;
    position: absolute;
    bottom: 0;
    user-select: none;
  }
  
  .testimony-btn {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: none;
    cursor: pointer;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    border-radius: 5% 5% 0% 0%;
  }
  
  .testimony-btn img {
    width: 75px; 
    height: 75px;
    padding: 1rem;
  }



  /* Plans & Pricing Section */
  .outer-landing-page-plans {
    background-color: white;
    padding: 3rem 0;
    text-align: center;
    padding-bottom: 5rem;
    border-radius: 0% 0% 50% 50%;
    width: 100%;
    position: relative;
  }
  
  .outer-landing-page-pricing-grid {
    display: flex; 
    flex-wrap: wrap; 
    width: 50%;
    margin: 0 auto; 
    justify-content: center; 
    z-index: 1;
  }

  .outer-landing-page-plan{
    display: flex;
    flex-direction: column; 
    flex: 1; 
    margin: 1%;
    max-width: 23%; 
    min-height: 400px; 
    position: relative; 
  }

  .outer-landing-page-plan-header {
    background-color: #fff;
    padding: 2rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    color:white;
    text-align: left;
    user-select: none;
    font-family: 'Poppins', sans-serif;
    font-weight: 'normal';
    z-index: 1;
  }

  .outer-landing-page-plans-body{
    background-color: rgba(255,255,255,0.9);
    text-align: left;
    padding:2rem;
    height: 100%;
    z-index: 1;
  }

  .outer-landing-page-plan-target{
    font-size: 0.75rem;
    font-family: 'Poppins', sans-serif;
    font-weight: 'normal';
    text-align: justify;
    color: rgba(136, 165, 176, 0.75);
    padding-bottom: 1rem;
    user-select: none;
  }

  .outer-landing-page-plan-everything{
    padding-top: 1rem;
    font-size: 0.75rem;
    font-family: 'Poppins', sans-serif;
    font-weight: 'medium';
    text-align: justify;
    color: rgba(136, 165, 176, 1);
    user-select: none;
  }

  .outer-landing-page-plan-colored-spacer{
    width: 80%;
    height: 3px;
  }


  .outer-landing-page-plan-button-blue, .outer-landing-page-plan-button-yellow,.outer-landing-page-plan-button-orange,.outer-landing-page-plan-button-green {
    width: 100%;
    color: white;
    padding: 1rem 1rem;
    border: none;
    font-size: 1rem;
    cursor: pointer;
    transition: background 0.3s ease;
    border: 1px solid white;
    user-select: none;
    z-index: 1;
    margin-top: 0.25rem;
    margin-bottom: 2rem;
  }

  .outer-landing-page-plan-button-blue {
    background-color: #526dfd;
  }
  
  .outer-landing-page-plan-button-blue:hover {
    background-color: white;
    color: #526dfd;
    border: 1px solid #526dfd;
  }

  .outer-landing-page-plan-button-yellow {
    background-color: #ffab00;
  }
  
  .outer-landing-page-plan-button-yellow:hover {
    background-color: white;
    color: #ffab00;
    border: 1px solid #ffab00;
  }

  .outer-landing-page-plan-button-green {
    background-color: #00c853;
  }
  
  .outer-landing-page-plan-button-green:hover {
    background-color: white;
    color: #00c853;
    border: 1px solid #00c853;
  }

  .outer-landing-page-plan-button-orange {
    background-color: #ff7043;
  }
  
  .outer-landing-page-plan-button-orange:hover {
    background-color: white;
    color: #ff7043;
    border: 1px solid #ff7043;
  }

  .outer-landing-page-plan-entry{
    margin-top: 0.5rem;
    display: flex;
    flex-direction: row;
    text-align: center;
  }

  .outer-landing-page-plan-entry-text{
    font-size: 0.75rem;
    font-family: 'Poppins', sans-serif;
    font-weight: 'normal';
    text-align: justify;
    color: rgba(136, 165, 176, 0.75);
    padding-bottom: 1rem;
    user-select: none;
  }

  .outer-landing-page-plan-checkmark-green, .outer-landing-page-plan-checkmark-yellow, .outer-landing-page-plan-checkmark-orange, .outer-landing-page-plan-checkmark-blue {
    height: 10px;
    margin-right: 0.5rem;
    -webkit-mask: url('../../assets/img/outerLanding/planCheckmark.png') no-repeat center; 
    -webkit-mask-size: contain;
    mask: url('../../assets/img/outerLanding/planCheckmark.png') no-repeat center;
    mask-size: contain;
    transform: translateY(25%);
  }

  .outer-landing-page-plan-checkmark-green {
    background-color: #00c853;
  }

  .outer-landing-page-plan-checkmark-blue {
    background-color: #526dfd;
  }

  .outer-landing-page-plan-checkmark-yellow {
    background-color: #ffab00;
  }

  .outer-landing-page-plan-checkmark-orange {
    background-color: #ff7043;
  }

  .planCircles {
    position: absolute;
    padding: 3rem;
    top: 0;
    left: -50%;
    transform: translateX(150%);
    height: 100%;
    width: 50%;
    z-index: 0;
  }

  
  /* About Section */
  .outer-landing-page-about {
    background-color: transparent;
    text-align: center;
    transform: translateY(-15%);
    
  }

  .outer-landing-page-about-inner{
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    user-select: none;
    position: relative;
  }

  .decorativePencils{
    position: absolute;
    right: 15%;
    top: 0;
    width: 15%;
  }

  .about-square {
      display: flex;
      flex-direction: row;
      width: 60%;
      margin-left: auto;
      margin-right: auto;
      background-color: white;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      margin-bottom: 2%;
      position: relative;

  }

  .about-image {
      width: 30%; /* Ensure the image fills the width of its container */
      height: auto; /* Automatically adjust height to maintain aspect ratio */
      object-fit: cover; /* Ensures the image covers the entire area without stretching */
  }

  .about-inner-text{
    text-align: left;
      padding: 2rem 3rem;
      color:#90a4ae;
  }

  .about-inner-title{
    font-size: 1.5vw;
    color:#90a4ae;
    font-family: 'Poppins', sans-serif;
    font-weight: 'semibold';
  }
  
  .outer-landing-page-about-description {
    font-size: 1vw;
    font-family: 'Poppins', sans-serif;
    font-weight: 'normal';
    color: rgba(136, 165, 176, 0.75);
  }

  .outer-landing-image-container {
    position: relative;
    display: inline-block;
    overflow: hidden;
  }

  .readyToImproveImg {
      width: 100%;
      user-select: none;
      display: block;
      user-select: none;
  }

  .shadow {
    position: absolute;
    bottom: 0; 
    left: 0;
    right: 0;
    height: 30%; 
    background: linear-gradient(to top, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0)); /* Gradient shadow */
    filter: blur(10px); 
    z-index: 1; 
  }

  .overlay-text {
      position: absolute;
      bottom: -3vw;
      width: 100%;
      left: 50%;
      transform: translateX(-50%);
      color: white;
      font-size: clamp(3vw, 7.5vw, 10vw); /* Adjust the values to fit your needs */
      z-index: 0;
      text-align: center;
      font-family: 'Poppins', sans-serif;
      font-weight: medium;
      font-style: medium;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      user-select: none;
      text-shadow:
              -1px -1px 0 #222f33,
              1px -1px 0 #222f33,
              -1px  1px 0 #222f33,
              1px  1px 0 #222f33;
  }


  .about-link {
    color: #08aeff;
    text-decoration: none;
    font-weight: bold;
  }
  
  .about-link:hover {
    color: #084b6d;
  }
  
  /* Contacts Section */
  .outer-landing-page-contacts {
    padding: 0rem 0;
    text-align: center;
    position: relative;
    z-index: 0;
  }

  .outer-landing-page-contacts-white-decoration{
    position: absolute;
    top:0;
    content: "";
    background-color: white;
    height: 20%;
    width: 100%;
    z-index: -1;
  }

  .outer-landing-page-contacts-white-decoration-triangle{
    position: absolute;
    top:20%;
    content: "";
    background-color: white;
    height: 7.5vh;
    width: 100%;
    z-index: -1;
    clip-path: polygon(50% 100%, 0 0, 100% 0);
  }
  
  .outer-landing-page-contact-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    z-index: 1;
    margin-top: 2rem;
    margin-bottom: 10rem;
  }
  
  .outer-landing-page-input,
  .outer-landing-page-textarea {
    width: 40%;
      padding: 2rem 0 2rem 2rem;
      margin-bottom: 1rem;
    user-select: none;
    font-family: 'Poppins', sans-serif;
    font-weight: 'normal';
    z-index: 1;
    resize: none;
    border: 1px solid; 
    border-image: linear-gradient(to right, #ff7043, #ffab00, #00c853, #526dfd) 1; 
}
  
  .outer-landing-page-submit-button {
    padding: 1rem 2rem;
    width: 40%;
    background-color: #00c853;
    color: white;
    padding: 2rem 2rem;
    border: none;
    font-size: 1rem;
    cursor: pointer;
    transition: background 0.3s ease;
    border: 1px solid white;
    user-select: none;
    font-family: 'Poppins', sans-serif;
    font-weight: 'normal';
    z-index: 1;
  }

  .outer-landing-page-submit-button:hover {
    background-color: white;
    color: #00c853;
    border: 1px solid #00c853;
  }

  .outer-landing-page-contacts-button {
    width: 25%;
    background-color: #00c853;
    color: white;
    padding: 2rem 2rem;
    border: none;
    font-size: 1rem;
    cursor: pointer;
    transition: background 0.3s ease;
    border: 1px solid white;
    user-select: none;
    z-index: 1;
    margin-top: 3rem;
    margin-bottom: 2rem;
  }
  
  .outer-landing-page-contacts-button:hover {
    background-color: white;
    color: #00c853;
    border: 1px solid #00c853;
  }

  .outer-landing-page-thanks{
    color: #90a4ae;
    font-size: 2rem;
    user-select: none;
    font-family: 'Poppins', sans-serif;
    font-weight: normal;
    z-index: 1;
  }

  .outer-landing-page-get-back{
    width: 50%;
    font-size: 1rem;
    color: rgba(136, 165, 176, 0.75);
    margin-left: auto;
    margin-right: auto;
    text-align: justify;
    text-align-last: center;
    user-select: none;
    font-family: 'Poppins', sans-serif;
    font-weight: 'normal';
  }
  
  /* Footer */
  .outer-landing-page-footer {
    background-color: #526dfd;
    border: 1px solid #5767ff;
    color: white;
    text-align: center;
    padding: 2rem 0rem;
    user-select: none;
  }
  
  .outer-landing-page-footer-text {
    font-size: 1rem;
    font-family: 'Poppins', sans-serif;
    font-weight: 'normal';
  }

  .outer-landing-page-footer-green-wrapper{
    border-top: 5px solid #00c750;
  }

  .outer-landing-page-footer-orange-wrapper{
    border-bottom: 15px solid #ff7043;
  }

  .outer-landing-page-footer-yellow-wrapper{
    border-bottom: 15px solid #ffab00;
    margin-bottom: 2rem;
  }


.outer-landing-page-footer-columns {
  display: flex;
  justify-content: space-around; /* Distribute space evenly */
  flex-wrap: wrap; /* Allow wrapping on smaller screens */
  width: 90%;
}

.outer-landing-page-footer-column {
  flex: 1;
  margin: 0 1rem; 
  text-align: left;
  align-items: left;
  justify-content: left;
}

.outer-landing-page-footer-image {
  width: 100%; 
  height: auto; 
}

.outer-landing-page-footer-title {
  font-size: 1.2rem;
  font-family: 'Poppins', sans-serif;
  font-weight: medium;
  margin: 0.5rem 0;
  color: white;
  text-transform: capitalize;
}

.outer-landing-page-footer-links {
  list-style: none;
  padding: 0; 
  display: flex;
  flex-direction: column;
  text-align: left;
  align-items: left;
  justify-content: left;
}

.outer-landing-page-footer-links li {
  margin: 0.2rem 0; 
  width: 100%;
}

.outer-landing-page-footer-link {
  color: white;
  text-decoration: none; 
  font-family: 'Poppins', sans-serif;
  font-weight: normal;
  text-align: left;
  align-items: left;
  justify-content: left;
  width: 100%;
  font-size: 0.8rem; 
  cursor: pointer;
}

.outer-landing-page-footer-links a:hover {
  color: #9cdcfe;
}

.outer-landing-page-dropdown {
    display: none;
    z-index: 3;
}

.outer-landing-page-close-icon {
    color: #90A4AE;
}

  .outer-landing-page-dropdown-footer {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      display: flex;
      justify-content: flex-start;
      padding: 3rem 0.8rem;
  }

  .outer-landing-page-dropdown-footer a {
      margin: 0 1rem;
  }

  .outer-landing-page-dropdown-footer-icon {
      font-size: 8vw;
  }

@media (max-width: 1300px) {
    .outer-landing-page-pricing-grid {
        width: 80%;
    }

    .testimony-text {
        font-size: 1.25vw;
    }

    .testimony-title {
        font-size: 2vw;
    }

    .feature-title {
        font-size: 1.75vw;
    }

    .feature-description {
        font-size: 1vw;
    }
}

@media (max-width: 1000px) {
    .planCircles {
        display: none;
    }

    .outer-landing-page-nav-list {
        margin-top: 3vh;
    }

    .outer-landing-page-nav-link {
        font-size: 1.5vw;
    }

    .outer-landing-page-nav {
        align-items: normal;
    }

    .outer-landing-page-header {
        margin-left: 5%;
    }

    .outer-landing-page-hero-content {
        width: 100%;
        padding-left: 5em;
        margin-left: 0;
    }

    .outer-landing-page-hero-title {
        font-size: 2.75vw;
    }

    .outer-landing-page-hero-description {
        font-size: 1.75vw;
    }

    .outer-landing-page-feature-list {
        display: flex;
        flex-direction: column;
        width: 90%;
    }

    .feature-title {
        font-size: 2.25vw;
    }

    .feature-description {
        font-size: 1.65vw;
    }

    .about-inner-title {
        font-size: 2vw;
    }

    .outer-landing-page-about-description {
        font-size: 1.5vw;
    }

    .about-square {
        width: 90%;
    }

    .outer-landing-page-input, .outer-landing-page-textarea {
        width: 75%;
    }

    .outer-landing-page-plan {
        width: 50%;
        max-width: none;
    }

    .outer-landing-page-pricing-grid {
        flex-direction: column;
        align-items: center;
    }

    .outer-landing-page-section-title {
        font-size: 4.25vw;
    }

    .testimony-content {
        margin-left: 15vw;
        width: 50%;
    }

    .testimony-title {
        font-size: 2.5vw;
    }

    .testimony-text {
        font-size: 1.75vw;
    }

    .outer-landing-page-feature-animation {
        width: 80%;
        margin-top: -2rem;
    }

    .outer-landing-page-benefits-text {
        width: 80%;
    }
}

  @media (max-width: 768px) {
      .outer-landing-page-nav-link {
          font-size: 2.25vw;
      }

      .outer-landing-page-dropdown {
          display: flex;
      }

      .outer-landing-page-nav-list {
          position: fixed;
          top: 0;
          left: 0;
          width: 100vw;
          height: 100vh;
          background-color: white;
          display: flex;
          align-items: flex-start;
          justify-content: center;
          flex-direction: column;
          z-index: 2;
          transition: opacity 0.3s ease-in-out;
          padding-bottom: 15vh;
          margin-top: 0;
      }

      .outer-landing-page-nav-link {
          color: #90A4AE;
          font-size: 5vw;
          font-weight: normal;
          margin-left: -10px;
      }

      .outer-landing-page-nav {
          align-items: center;
      }

  }

  @media (max-width: 600px) {
      .feature-title {
          font-size: 2.75vw;
      }

      .feature-description {
          font-size: 2vw;
      }

      .outer-landing-page-hero-title {
          font-size: 4.25vw;
      }

      .outer-landing-page-hero-description {
          font-size: 2.75vw;
      }

      .outer-landing-page-hero-content {
          padding-left: 1em;
      }

      .outer-landing-page-scroll-bar {
          padding-left: 1em;
          padding-right: 0;
          width: 100%;
      }

      .outer-landing-page-hero-button {
          width: 50%;
          font-size: 2.75vw;
          padding: 1rem 1rem;
      }

      .outer-landing-page-plan {
          width: 75%;
      }

      .outer-landing-page-section-title {
          font-size: 5.75vw;
      }

      .about-inner-title {
          font-size: 3.5vw;
      }

      .outer-landing-page-about-description {
          font-size: 2.25vw;
      }

      .about-inner-text{
          padding: 2rem 1rem;
      }

      .outer-landing-page-contacts-button {
          width: 60%;
          padding: 1rem 1rem;
      }

      .testimony-content {
          margin-left: 10vw;
          width: 80%;
          align-items: center;
      }

      .testimony-title {
          font-size: 4vw;
      }

      .testimony-text {
          font-size: 2.25vw;
      }

      .testimony-btn img {
          width: 15vw;
          height: auto
      }

      .outer-landing-page-footer-columns {
          flex-direction: column;
          align-items: center;
          width: 100%;
      }

      .outer-landing-page-footer-columns img {
          margin-bottom: 1rem;
      }

      .outer-landing-page-footer-column {
          text-align: center;
      }

      .outer-landing-page-footer-links {
          text-align: center;
      }

      .outer-landing-page-pricing-grid {
          width: 90%;
      }

      .about-square {
          flex-direction: column;
      }

      .about-image {
          width: 100%;
          height: 25vh;
      }
  }

  @media (max-width: 450px) {
      .testimony-text {
          font-size: 2.75vw;
      }

      .testimony-title {
          font-size: 5vw;
      }

      .feature-title {
          font-size: 3.5vw;
      }

      .feature-description {
          font-size: 2.5vw;
      }

      .outer-landing-page-hero-title {
          font-size: 4.5vw;
      }

      .outer-landing-page-hero-description {
          font-size: 3vw;
      }

      .outer-landing-page-nav-link {
          font-size: 7vw;
      }

  }