.delete-resource-dialog-content {
    color: white;
    display: flex;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
}

.delete-resource-warning-margin-right {
    margin-right: 15px;
}

.delete-resource-warning-margin-left {
    margin-left: 15px;
}