.recent-project-card {
    background-color: #252224 !important;
    margin-bottom: 16px !important;
    margin-right: 30px !important;
}

.recent-project-divider {
    margin-bottom: 15px !important;
    background-color: white !important;
}

.recent-project-progress {
    margin-bottom: 16px;
}

.recent-project-linear-progress {
    margin-bottom: 16px;
    background-color: #484848;
}

.recent-project-progress-label {
    display: flex;
    align-items: center;
    margin-bottom: 8px
}

.recent-project-overall-progress {
    margin-right: 8px;
    flex: 1;
}

@media (max-width: 1200px) {
    .recent-project-card {
        margin-left: 2.5vh;
        margin-right: 2.5vh;
    }
}