.profile-page-background {
    background-color: #3D393B;
    width: 100%;
    min-height: 100vh;
}

.profile-container {
    max-width: 100%;
    padding: 0 !important;
}

.profile-grid-container {
    width: 100%;
    margin: 0;
}

.profile-reset-password-button-container {
    margin-right: 16px;
    margin-bottom: 16px;
}

.profile-reset-password-button {
    margin-top: 8px !important;
}

.profile-card {
    background-color: #252224 !important;
    margin-bottom: 16px;
    margin-right: 30px;
}

.profile-card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.profile-card-title {
    color: #FFF !important;
    margin-bottom: 16px;
}

.profile-card-divider {
    margin-bottom: 15px;
    background-color: white !important;
}

@media (max-width: 1200px) {
    .profile-card {
        margin-left: 2.5vh;
    }

    .profile-reset-password-button-container {
        margin-left: 2.5vh;
        margin-right: 2.5vh;
    }

    .profile-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin: 0 auto;
    }


}