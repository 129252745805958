.refutal-notification-title, .refutal-notification-text {
    color: #ffffff;
    text-align: center;
}

.refutal-notification-text {
    margin-top: 16px !important;
}

.refutal-notification-button-box {
    display: flex;
    justify-content: center;
    margin-top: 32px;
}

.refutal-notification-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    background-color: #1c1c1c;
    border: 2px solid #2f2f2f;
    box-shadow: 0 0 24px rgba(0, 0, 0, 0.2);
    padding: 32px;
}

@media (max-width: 1000px) {
    .refutal-notification-modal {
        width: 60%;
    }
}

@media (max-width: 600px) {
    .refutal-notification-modal {
        width: 80%;
    }

    .refutal-notification-title {
        font-size: 1.25rem !important;
    }
}