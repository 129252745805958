.form-control-new-project {
    width: 100% !important;
    background-color: transparent !important;
}

.form-control-new-project .MuiSelect-select {
    color: #fff !important;
    background-color: transparent !important
}

.select-menu-new-project .MuiMenuItem-root{
    color: #fff !important;
}

.select-menu-new-project{
    background-color: #333 !important;
}