.user-activity-modal {
    display: flex;
    align-items: center;
    justify-content: center;
}

.user-activity-box {
    width: 80%;
    max-height: 80vh;
    background-color: #1E1E1E;
    color: #FFFFFF;
    padding: 20px;
    overflow-y: auto;
    box-shadow: 0 0 24px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    max-width: 600px;
}

.user-activity-search-input {
    margin-bottom: 20px !important;
    width: 100%;
}

.user-activity-search-input .MuiInputBase-input {
    color: white;
}

.user-activity-search-input .MuiOutlinedInput-root {
    border-color: #555555;
}

.user-activity-search-input .MuiOutlinedInput-root:hover fieldset {
    border-color: #888888;
}

.user-activity-search-input .MuiOutlinedInput-root.Mui-focused fieldset {
    border-color: #888888;
}