.vacation-calendar-actions-box {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    margin-top: 8px;
    gap: 8px;
}

.vacation-calendar-approve {
    color: #ffffff !important;
    display: flex !important;
}

.vacation-calendar-approve-disabled {
    background-color: #ccc !important;
}

.vacation-calendar-approve-active {
    background-color: #4caf50 !important;
}

.vacation-calendar-edit {
    background-color: #1976d2 !important;
    color: #ffffff !important;
    display: flex !important;
}

.vacation-calendar-refute {
    background-color: #ff9800 !important;
    color: #ffffff !important;
    display: flex !important;
}

.vacation-calendar-decline {
    background-color: #f44336 !important;
    color: #ffffff !important;
    display: flex !important;
}

.vacation-calendar-component {
    display: flex !important;
    justify-content: center !important;
    margin-bottom: 20px !important;
    margin-top: 10px !important;
}

.vacation-calendar-checkbox {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.vacation-calendar-refute-modal {
    padding: 24px;
    background-color: #2B2B2B;
    border-radius: 8px;
    width: 400px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.vacation-calendar-refute-title {
    margin-bottom: 16px !important;
    color: #D3D3D3 !important;
}

.vacation-calendar-input-title-color {
    color: #D3D3D3 !important;
}

.vacation-calendar-input-color {
    color: #D3D3D3 !important;
    background-color: #3B3B3B !important;
}


.vacation-calendar-refute-buttons {
    margin-top: 24px;
}

.vacation-calendar-refute-input .MuiOutlinedInput-root fieldset {
    border-color: #4F4F4F;
}

.vacation-calendar-refute-input .MuiOutlinedInput-root:hover fieldset {
    border-color: #6A6A6A;
}

.vacation-calendar-refute-input .MuiOutlinedInput-root.Mui-focused fieldset {
    border-color: #9A9A9A;
}

.vacation-calendar-tooltip-title {
    display: block;
    text-align: center;
    font-weight: bold;
}

.vacation-calendar-tooltip-description {
    border: 1px dashed #ccc;
    padding: 8px;
    margin-top: 8px;
    margin-bottom: 10px;
}

.vacation-calendar-no-deduct-text {
    color: orange;
}

.vacation-calendar-user-info {
    display: block;
    text-align: center;
}

.vacation-calendar-event-style {
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: 100%;
    position: relative;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    word-wrap: break-word;
    background-size: 10px 10px;
    border-style: solid;
    border-width: 2px;
}

.vacation-calendar-left-arrow {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    color: black;
    font-size: 18px;
}

.vacation-calendar-event-title {
    text-align: center;
}

.vacation-calendar-right-arrow {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    color: black;
    font-size: 18px;
}

.vacation-calendar-fc {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    margin-left: 2.5%;
    margin-right: 2.5%;
}

@media (max-width: 1000px) {
    .vacation-calendar-refute-modal {
        width: 60%;
    }
}

@media (max-width: 600px) {
    .vacation-calendar-refute-modal {
        width: 80%;
    }
}