.view-task-dialog .MuiPaper-root {
    max-width: 1000px !important;
}

.view-task-scrollable-content {
    overflow-x: hidden;
}

.view-task-add-label-icon {
    color: #D6D4D4 !important;
    margin-left: 5px;
}

.view-task-label-add {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}

.view-task-cover {
    border-radius: 8px 8px 0 0;
    background-size: cover;
    background-position: center;
}

.view-task-cover.no-image {
    height: 90px;
    min-height: 90px;
}

.view-task-cover.with-image {
    height: 200px;
    min-height: 200px;
}

.view-task-dialog-title {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    overflow-x: auto;
    flex-direction: column;
}

.view-task-dialog-title::-webkit-scrollbar {
    display: none;
}

.view-task-title-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    overflow-wrap: anywhere;
}

.view-task-width {
    width: 100vh !important;
}

.view-task-in-list {
    font-size: small;
    font-style: italic;
}

.view-task-list-error {
    font-size: small;
    margin: 2px;
    color: red;
}

.view-task-full-width {
    width: 1000px;
}

.view-task-content-box {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
}

.view-task-content-div {
    display: flex;
    flex-direction: column;
}

.view-task-row-flex {
    display: flex;
    flex-direction: row;
}

.view-task-label-margin {
    margin-bottom: 5px;
}

.view-task-label-title {
    font-size: 12px !important;
    font-weight: bold !important;
    color: white !important;
    padding-right: 3px !important;
    padding-left: 3px !important;
}

.view-task-label-title.with-shadow {
    text-shadow: 1px 1px black !important;
}

.view-task-label-title.no-shadow {
    text-shadow: none !important;
}

.view-task-label-dialog .MuiPaper-root {
    background: #252224;
    display: flex;
    text-align: -webkit-center;
}

.view-task-add-label-actions {
    display: flex;
    justify-content: space-between;
}

.view-task-description-margin {
    margin-bottom: 15px;
}

.view-task-description-text-div {
    width: 95%;
    margin-bottom: 15px;
    color: white;
}

.view-task-activity-log-margin {
    margin-bottom: 10px !important;
}

.view-task-activity-log-div {
    max-height: 200px;
    overflow-y: auto;
    padding-right: 10px !important;
}

.view-task-tooltip {
    color: #5e72e4;
    cursor: pointer;
}

.view-task-tooltip:hover {
    color: #233DD2;
}

.view-task-activity-log-avatar-margin {
    margin-right: 10px;
}

.view-task-activity-log-date-text {
    color: #67b5d9;
    font-style: italic;
}

.view-task-show-more-activity-log {
    margin-top: 10px !important;
}

.view-task-actions-border {
    border-left: 2px solid #575054;
}

.view-task-actions-border.show {
    display: block;
}

.view-task-actions-border.hide {
    display: none;
}

.view-task-actions {
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    margin-bottom: 15px;
}

.view-task-actions-title {
    color: white !important;
    margin-bottom: 8px !important;
}

.view-task-actions-button {
    border-radius: 15px !important;
    background-color: #575054 !important;
    width: 100% !important;
    color: #ffffff !important;
    margin-bottom: 15px !important;
}

.view-task-actions-button:hover {
    background-color: #463e3c !important;
}

.view-task-search-workers {
    margin-bottom: 16px !important;
    background-color: #fff;
    border-radius: 5px;
    width: 100%;
}

.view-task-workers-list {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}

.view-task-upload-button {
    color: #a3989e !important;
    background-color: transparent !important;
    display: flex !important;
    align-items: center !important;
    text-transform: none !important;
}

.view-task-upload-button:hover {
    background-color: rgba(255, 255, 255, 0.1) !important;
}

.view-task-attachments-icon {
    margin-right: 8px;
    color: white;
}

.view-task-attachments-list {
    margin-top: 16px;
}

.view-task-image-preview {
    display: flex !important;
    align-items: center !important;
    margin-bottom: 8px !important;
    color: #ffffff !important;
    background-color: rgba(255, 255, 255, 0.1) !important;
    padding: 8px !important;
    border-radius: 8px !important;
    cursor: pointer !important;
}

.view-task-image-preview:hover {
    background-color: rgba(255, 255, 255, 0.2) !important;
}

.view-task-attachment-box {
    flex: 1;
}

.view-task-attachment-delete-icon {
    margin-left: 8px !important;
    color: red !important;
}

.view-task-image-preview-modal {
    padding: 0 !important;
    position: relative;
}

.view-task-image-preview-close-icon {
    position: absolute !important;
    top: 8px !important;
    right: 8px !important;
    z-index: 2 !important;
    color: white !important;
}

.view-task-delete-attachment-title {
    color: #a3989e;
}


.view-task-edit-title {
    display: inline-block;
    width: 100%;
}


.view-task-label-title-input {
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 1.334 !important;
    color: #C0C0C0 !important;
}

.view-task-label-color-input {
    font-size: 13px !important;
    margin-top: 15px !important;
}

.view-task-flex {
    display: flex;
    flex-wrap: wrap;
}

.view-task-label-style {
    border-radius: 15px;
    padding: 2px;
    margin-right: 5px;
    cursor: pointer;
    min-width: max-content;
    display: flex;
    justify-content: center;
    margin-top: 8px;
}

@keyframes rainbow {
    0% { background-position: 0 50%; }
    50% { background-position: 100% 50%; }
    100% { background-position: 0 50%; }
}

.view-task-label-style.rainbow, .view-task-label-list-style.rainbow, .view-task-label-color-picker.rainbow {
    background: linear-gradient(270deg, #ff0000, #ff7f00, #ffff00, #00ff00, #0000ff, #4b0082, #8b00ff);
    background-size: 400% 400%;
    animation: rainbow 1.5s ease infinite;
}

.view-task-label-list-style.rainbow {
    animation: rainbow 2.5s ease infinite !important;
}

.view-task-label-list-style {
    width: 225px;
    min-height: 30px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    padding: 0 10px;
    justify-content: space-between;
    cursor: pointer;
}

.view-task-label-list-style.selected {
    border: 2px solid white !important;
}

.view-task-member-list {
    display: flex;
    align-items: center;
    padding: 0 10px;
    cursor: pointer;
    border-radius: 5px;
    background-color: transparent;
    width: 100%;
}

.view-task-member-list.selected {
    border: 2px solid white !important;
    background-color: #444 !important;
}

.view-task-label-list-icons {
    margin-left: 8px;
}

.view-task-label-color-picker {
    margin-top: 15px;
    margin-bottom: 20px;
    width: 100%;
    height: 150px;
}

.view-task-label-colors {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 5px;
    margin-top: 5px;
}

.view-task-label-color-item {
    width: 100%;
    height: 30px;
    cursor: pointer;
    border-radius: 10px;
}

.view-task-activity-log-list {
    color: white;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
}

.view-task-worker-name {
    margin-left: 15px;
    color: white;
}

.view-task-attachment-name {
    word-break: break-all;
}

.view-task-attachment-image-preview {
    width: auto;
    height: auto;
    max-height: 90vh;
}

.view-task-cover-image-preview {
    width: -webkit-fill-available;
}

.view-task-dropdown-button {
    display: flex;
    padding: 20%;
}

.view-task-buttons {
    justify-content: flex-end;
    align-items: center;
    margin-top: 3%;
    margin-left: 5vh;
    display: none;
    align-self: flex-end;
}


.view-task-menu-actions {
    background-color: #252224 !important;
    color: #C0C0C0 !important;
    max-width: 230px !important;
}

.view-task-menu-item {
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    color: #C0C0C0 !important;
    max-width: 200px !important;
}

.view-task-menu-divider {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
    border-color: #3A3A3A !important;
}

@media (max-width: 1050px) {
    .view-task-full-width {
        width: auto;
    }
}

@media (max-width: 900px) {
    .view-task-buttons {
        display: flex;
    }

    .view-task-buttons.hide {
        display: none !important;
    }

    .view-task-buttons.show {
        display: flex !important;
    }

    }