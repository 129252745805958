/* Holiday Animation (Gold) */
@keyframes sparkle {
    0% { transform: translate(0, 0); opacity: 1; }
    25% { transform: translate(5px, -5px); opacity: 0.8; }
    50% { transform: translate(-5px, 5px); opacity: 1; }
    75% { transform: translate(10px, -10px); opacity: 0.8; }
    100% { transform: translate(0, 0); opacity: 1; }
}

@keyframes pulse {
    0% { box-shadow: 0 0 5px rgba(255, 255, 0, 0.8); }
    50% { box-shadow: 0 0 20px rgba(255, 255, 0, 1), 0 0 30px rgba(255, 255, 0, 0.9); }
    100% { box-shadow: 0 0 5px rgba(255, 255, 0, 0.8); }
}

/* Sparkle effect for holidays */
.sparkle {
    background-color: gold !important;
    position: relative;
    animation: pulse 1.5s infinite;
    border-radius: 5px;
    overflow: visible;
}

.sparkle::before, .sparkle::after {
    content: '✨';
    position: absolute;
    font-size: 20px;
    color: gold;
    text-shadow: 0px 0px 2px black, 0px 0px 2px black;
    animation: sparkle 2s infinite;
}

.sparkle::before {
    top: -15px;
    left: -15px;
}

.sparkle::after {
    bottom: -15px;
    right: -15px;
    animation-delay: 0.5s;
}

/* Vacation Animation (Grey) */
@keyframes vacationPulse {
    0% { box-shadow: 0 0 5px rgba(128, 128, 128, 0.8); }
    50% { box-shadow: 0 0 20px rgba(128, 128, 128, 1), 0 0 30px rgba(128, 128, 128, 0.9); }
    100% { box-shadow: 0 0 5px rgba(128, 128, 128, 0.8); }
}

@keyframes vacationSparkle {
    0% { transform: translate(0, 0); opacity: 1; }
    25% { transform: translate(5px, -5px); opacity: 0.8; }
    50% { transform: translate(-5px, 5px); opacity: 1; }
    75% { transform: translate(10px, -10px); opacity: 0.8; }
    100% { transform: translate(0, 0); opacity: 1; }
}

/* Grey color and 🌴 for vacations */
.vacation {
    background-color: #B0B0B0 !important;
    position: relative;
    animation: vacationPulse 1.5s infinite;
    border-radius: 5px;
    overflow: visible;
}

.vacation::before, .vacation::after {
    content: '🏖️🌴';
    position: absolute;
    font-size: 20px;
    color: grey;
    text-shadow: 0px 0px 2px black, 0px 0px 2px black;
    animation: vacationSparkle 2s infinite;
}

.vacation::before {
    top: -15px;
    left: -15px;
}

.vacation::after {
    bottom: -15px;
    right: -15px;
    animation-delay: 0.5s;
}

.vis-calendar-main-div {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.vis-calendar-today-title {
    display: flex !important;
    justify-content: center !important;
    margin-bottom: 20px !important;
    margin-top: 10px !important;
}

.vis-calendar-checkboxes {
    display: flex;
    justify-content: center;
    gap: 15px;
    margin-bottom: 20px;
}

.vis-calendar-filter-users-button {
    margin: 20px auto !important;
}

.vis-calendar-filter-users-modal {
    margin-left: 0 !important;
}

.vis-calendar-filter-users-modal.sidebar-open {
    margin-left: 240px !important;
}

.vis-calendar-filter-users-modal .MuiDialogContent-root, .vis-calendar-filter-users-modal .MuiDialogTitle-root {
    background-color: #2B2B2B;
}

.vis-calendar-search-users {
    margin-bottom: 12px !important;
    margin-top: 5px !important;
    width: 100% !important;
}

.vis-calendar-timelineref {
    width: 95%;
    margin: 0 auto;
    background-color: white;
}

.vis-calendar-pagination {
    color: #ffffff !important;
}

.vis-calendar-pagination .MuiTablePagination-selectLabel, .vis-calendar-pagination .MuiTablePagination-displayedRows {
    align-self: flex-end !important;
}

@media (max-width: 500px) {
    .vis-calendar-checkboxes {
        flex-direction: column;
        align-items: center;
    }
}