.workflow-main-content {
    height: 100%;
    padding-left: 240px;
    padding-top: 120px;
}

.workflow-main-content-closed-bar {
    padding-top: 120px;
}

@media (max-width: 1000px) {
    .workflow-main-content {
        padding-left: 0;
        opacity: 0.6;
    }
}