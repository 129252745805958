.sidebar-list-list {
    overflow: hidden !important;
    max-height: none !important;
}

.sidebar-list-list.show-more {
    overflow: auto !important;
}

.sidebar-list-list.max-height {
    max-height: 300px !important;
}

.sidebar-list-star-icon {
    font-size: large !important;
    color: yellow;
}

.sidebar-list-item-button {
    display: flex;
    flex-direction: column;
}

.sidebar-list-text {
    color: #67B5D9 !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
}