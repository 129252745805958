.text-editor-dialog {
    width: 100%;
}

.text-editor-dialog .MuiPaper-root {
    background: #FFF;
}

.text-editor-width {
    width: 100% !important;
}

.text-editor-code-block {
    background-color: #3d393b;
    color: white;
    font-family: 'Courier New', Courier, monospace;
    margin: 0;
    padding: 10px;
    line-height: 1.2;
}

.text-editor-img {
    max-width: 100% !important;
    cursor: pointer !important;
}

.text-editor-validation-message {
    color: red !important;
}

.text-editor-editable-div {
    margin-bottom: 7px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.text-editor-text-area {
    border: 1px solid white;
    min-height: 6em;
    cursor: text;
    padding: 10px;
}