.profile-card-card {
    background-color: #252224 !important;
    margin-bottom: 16px !important;
    margin-right: 30px !important;
}

.profile-card-content {
    text-align: center;
}

.profile-card-avatar {
    width: 150px !important;
    height: 150px !important;
    margin: auto auto 16px;
}

.profile-card-job-title-margin {
    margin-bottom: 8px !important;
}

.profile-card-flag-image {
    margin-left: 7px;
    width: 25px;
}

.profile-card-online-status-box {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 8px;
}

@media (max-width: 1200px) {
    .profile-card-card {
        margin-left: 2.5vh;
        margin-right: 2.5vh;
    }
}