.badge-style .MuiBadge-badge {
    box-shadow: 0 0 0 2px white;
}

.badge-style .MuiBadge-badge::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 1px solid currentColor;
    content: "";
}

@keyframes badge-ripple {
    0% {
        transform: scale(.8);
        opacity: 1;
    }
    100% {
        transform: scale(2.4);
        opacity: 0;
    }
}

.badge-style-green .MuiBadge-badge {
    background-color: #44b700;
    color: #44b700;
}

.badge-style-orange .MuiBadge-badge {
    background-color: #FFA500;
    color: #FFA500;
}

.badge-style-red .MuiBadge-badge {
    background-color: #FF0000;
    color: #FF0000;
}

.badge-style-blue .MuiBadge-badge {
    background-color: #007bff;
    color: #007bff;
}

.badge-style-purple .MuiBadge-badge {
    background-color: #b700ff;
    color: #b700ff;
}

.badge-style-lightgrey .MuiBadge-badge {
    background-color: #898989;
    color: #898989;
}

.badge-style-white .MuiBadge-badge {
    background-color: #FFF;
    color: #FFF;
    box-shadow: 0 0 0 2px darkslategrey !important; 
}

.badge-style-green .MuiBadge-badge::after {
    animation: badge-ripple 1.2s infinite ease-in-out;
}

.badge-style-orange .MuiBadge-badge::after,
.badge-style-red .MuiBadge-badge::after,
.badge-style-purple .MuiBadge-badge::after {
    animation: none;
}

@media (max-width: 1200px) {
    .badge-style .MuiBadge-badge {
        width: 0.8rem;
        height: 0.8rem;
        border-radius: 50%;
        box-shadow: 0 0 0 3px white;
    }

    .badge-style {
        margin-left: 0.2rem;
    }
}