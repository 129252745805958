.dragging-task-paper-project-page {
    border: 1px solid #ccc !important;
    padding: 8px !important;
    background-color: #D6D4D4 !important;
    margin: 1% 1% 5% !important;
    cursor: grabbing !important;
    transform: rotate(2deg) !important;
    transition: transform 0.2s !important;
    border-radius: 8px !important;
}

.dragging-task-paper-project-page.cover {
    border-radius: 0 0 8px 8px !important;
}

.task-paper-project-page {
    transform: none !important;
}

.dragging-task-cover-project-page {
    border-radius: 8px 8px 0 0;
    height: 50px;
    background-size: cover;
    background-position: center;
    margin-bottom: 15px;
}

.dragging-task-cover-project-page.image{
    height: 200px !important;
}

.flex-project-page {
    display: flex !important;
    flex-wrap: wrap;
    overflow: hidden;
}

@keyframes rainbow {
    0% { background-position: 0 50%; }
    50% { background-position: 100% 50%; }
    100% { background-position: 0 50%; }
}

.label-style-project-page {
    border-radius: 15px;
    padding: 2px;
    margin-right: 5px;
    cursor: pointer;
    min-width: max-content;
    display: flex;
    justify-content: center;
}

.label-style-project-page.rainbow {
    background: linear-gradient(270deg, #ff0000, #ff7f00, #ffff00, #00ff00, #0000ff, #4b0082, #8b00ff);
    background-size: 400% 400%;
    animation: rainbow 1s ease infinite;
}

.label-title-project-page {
    font-size: 12px !important;
    font-weight: bold !important;
    color: white !important;
    padding-right: 3px !important;
    padding-left: 3px !important;
}

.label-title-project-page.no-shadow {
    text-shadow: none !important;
}

.label-title-project-page.with-shadow {
    text-shadow: 1px 1px black !important;
}

.access-time-icon-project-page {
    font-size: 16px;
    margin-right: 5px;
}

.checkbox-icon-project-page {
    color: #4A90E2 !important;
    margin-left: 4px !important;
}

.checkbox-text-project-page {
    color: #4A90E2 !important;
}

.members-avatar-group-project-page .MuiAvatar-root {
    border: 2px solid #000000 !important;
    width: 24px !important;
    height: 24px !important;
    font-size: small !important;
}

.members-avatar-project-page {
    width: 24px !important;
    height: 24px !important;
}

.over-arching-box-project-page {
    margin-bottom: 16px !important;
    border-radius: 12px !important;
    width: 300px !important;
    max-width: 300px !important;
    margin-left: 25px !important;
    margin-right: 25px !important;
    overflow-x: hidden !important;
}

.over-arching-box-project-page.collapsed {
    width: 50px !important;
}

.edit-list-project-page {
    padding-bottom: 20px !important;
    white-space: nowrap;
}

.edit-icon-project-page {
    color: #FFF !important;
    margin-left: 20px !important;
}

.list-icon-project-page {
    margin-left: 5px !important;
}

.badge-rotated-project-page {
    transform: rotate(90deg) !important;
}

.lock-icon-rotated-project-page {
    transform: rotate(90deg) !important;
    writing-mode: vertical-rl !important;
    font-size: 18px !important;
    margin-top: 15px !important;
}

.list-header-box-project-page {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: inherit;
}

.list-header-project-page {
    max-width: 90%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.typography-title-project-page {
    max-width: 100% !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    font-size: 12px !important;
    flex-wrap: nowrap !important;
}

.badge-project-page {
    margin-right: 15px !important;
}

.badge-project-page.locked {
    margin-right: 20px !important;
}

.lock-icon-project-page {
    font-size: 18px !important;
}

.box-style-project-page {
    margin-bottom: 16px !important;
    width: 300px !important;
    max-width: 270px !important;
    overflow-y: auto !important;
    overflow-x: hidden !important;
    height: 100% !important;
    max-height: calc(70vh - 120px) !important;
}

.box-style-project-page.collapsed {
    width: 50px !important;
    overflow-y: hidden !important;
}

.list-header-paper-project-page {
    border: 1px solid #ccc !important;
    background-size: cover !important;
    background-position: center !important;
    margin: 1% 1% 5% !important;
    height: 125px !important;
    display: flex !important;
    flex-direction: row !important;
    justify-content: center !important;
    align-items: center !important;
    text-align: center !important;
}

.list-header-emoji-project-page {
    font-size: 2rem !important;
    margin-right: 0.5rem !important;
}

.list-cover-paper-project-page {
    border-radius: 8px 8px 0 0 !important;
    height: 50px !important;
    margin-right: 1% !important;
    margin-left: 1% !important;
    margin-bottom: -8px !important;
    background-size: cover !important;
    background-position: center !important;
}

.list-cover-paper-project-page.image {
    height: 200px !important;
}

.add-icon-font-project-page {
    font-size: large !important;
}

.menu-item-task-project-page {
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    color: #C0C0C0 !important;
}

.menu-item-list-project-page {
    max-width: 200px !important;
}

.divider-project-page {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
    border-color: #3A3A3A !important;
}

.task-actions-project-page {
    color: #C0C0C0;
    margin-right: 8px;
}

.show-colors-project-page {
    width: 30px !important;
    height: 30px  !important;
    border-radius: 50%  !important;
    margin: 0.25rem  !important;
    cursor: pointer  !important;
    border: 2px solid white  !important;
}

@keyframes highlightAnimation {
    0% { background-color: yellow; }
    100% { background-color: transparent; }
}

.highlight-task-project-page {
    animation: highlightAnimation 3s ease-in-out;
}

.dragging-task-title-project-page {
    word-break: break-word;
    overflow-wrap: break-word;
}

.task-title-project-page {
    display: flex;
    justify-content: space-between;
    align-items: center;
    word-break: break-word;
    overflow-wrap: break-word;
}

.options-icon-task-project-page {
    cursor: pointer;
    margin-left: 10px;
    margin-bottom: 10px;
    align-self: flex-start;
    display: none !important;
}

.options-icon-list-project-page {
    display: none !important;
}


.dragging-task-dates-project-page {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.dragging-task-tooltip-project-page {
    display: flex;
    justify-content: flex-start;
    margin-left: 2px;
    align-items: center;
}

.dragging-task-tooltip-content-project-page {
    width: auto !important;
    height: 25px !important;
    border-radius: 10px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    font-size: 12px !important;
    padding: 0 5px !important;
}

.checkbox-div-project-page {
    display: flex;
    align-items: center;
}

.members-avatar-div-project-page {
    display: flex !important;
    justify-content: flex-end !important;
    margin-left: auto !important;
}

.label-expand-project-page {
    transition: width 0.5s ease-in-out;
}
.label-contract-project-page {
    transition: width 0.5s ease-in-out;
    width: 2ch;
}

.card-content-project-page {
    overflow: hidden !important;
    background-color: inherit !important;
    border-radius: 12px !important;
    max-height: 100vh !important;
    padding: 16px !important;
    color: inherit !important;
    display: flex !important;
    flex-direction: column !important;
}

.card-content-project-page.collapsed {
    max-height: 50vh !important;
    padding: 8px !important;
}

.card-content-project-page.isdragging {
    background-color: #2A3D75 !important;
    color: white !important;
}

.label-title-input-project-page {
    font-weight: 400 !important;
    font-size: 1.5rem !important;
    line-height: 1.334 !important;
}

.collapsed-list-div-project-page {
    display: flex !important;
    align-items: center !important;
    flex-direction: column !important;
}

.collapsed-list-gap-project-page {
    gap: 10px !important;
}

.collapsed-list-span-project-page {
    writing-mode: vertical-rl !important;
    margin-top: 8px !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    max-height: 38vh !important;
    padding-bottom: 10px !important;
}

.collapsed-list-map-project-page {
    position: initial !important;
    z-index: auto !important;
}

.collapsed-list-map-project-page.highlighted {
    position: relative !important;
    z-index: 9999 !important;
}

.add-icon-project-page {
    display: flex;
    color: #67B5D9;
    justify-content: flex-end;
    align-items: center;
}

.add-task-project-page {
    white-space: nowrap !important;
}

.menu-actions-project-page {
    background-color: #252224 !important;
    color: #C0C0C0 !important;
    max-width: 230px !important;
}

.menu-actions-list-project-page {
    max-width: 350px !important;
}

.color-options-project-page {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 8px;
    padding: 8px;
}

.dark-overlay-project-page {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.85);
    z-index: 1000;
    pointer-events: none;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.5s ease, visibility 1.0s ease;
}

.dark-overlay-project-page.visible {
    opacity: 1;
    visibility: visible;
}

.container-div-project-page {
    height: calc(100vh - 120px);
    overflow: auto;
    display: flex;
    flex-direction: column;
}

.flex-div-project-page {
    flex: 1 1 auto;
    overflow-x: auto;
    overflow-y: hidden;
    display: flex;
}

.my-lists-project-page {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    margin-top: 10px;
}

.edit-list-box-project-page {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
}

.edit-list-button-project-page {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-top: 16px;
}

.message-icon-project-page {
    margin-left: 2px !important;
    margin-right: 2px !important;
    font-size: 22px !important;
    margin-top: 2px !important;
}

@media (min-width: 600px) { /* sm */
    .typography-title-project-page {
        font-size: 14px !important;
    }
}

@media (max-width: 768px) {
    .options-icon-task-project-page {
        display: flex !important;
    }

    .options-icon-list-project-page {
        display: flex !important;
    }

    .label-style-project-page {
        margin-bottom: 5px;
    }
}

@media (min-width: 960px) { /* md */
    .typography-title-project-page {
        font-size: 16px !important;
    }
}

@media (min-width: 1280px) { /* lg */
    .typography-title-project-page {
        font-size: 18px !important;
    }
}

