.list-header-modal-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    background-color: #2e2e2e; /* Dark grey background color */
    border: 2px solid #444444;  /* Slightly lighter grey border color */
    box-shadow: 0 4px 24px rgba(0, 0, 0, 0.2);
    padding: 32px;
    color: #ffffff;
}

.list-header-flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 16px;
}

.list-header-text-field input {
    color: #ffffff;
}

.list-header-text-field label {
    color: #ffffff;
}

.list-header-color-box {
    display: flex;
    align-items: center;
}

.list-header-color-input {
    margin-left: 10px;
}

.list-header-file-input {
    display: none;
}

.list-header-upload-button {
    margin-top: 16px !important;
}

.list-header-preview-box {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.list-header-error {
    margin-top: 16px;
}

.list-header-emoji-box {
    display: flex;
    align-items: center;
    margin-top: 16px;
}

.list-header-emoji-icon {
    color: #ffffff;
}

.list-header-emoji {
    margin-left: 16px;
    font-size: 2rem;
    color: #ffffff;
}

.list-header-emoji-picker {
    margin-top: 16px;
}
.list-header-actions {
    margin-top: 16px;
    display: flex;
    justify-content: space-between;
}

.list-header-save-button {
    margin-left: 16px !important;
}

.list-header-text-color-margin {
    margin-left: 8px !important;
}