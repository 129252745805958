.fallback-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
    overflow: hidden;
}

.fallback-bar {
    width: 50px;
    height: 100px;
    background-size: cover;
    background-position: center;
    margin: 0 -10px;
    animation: pop 1.2s infinite;
    position: relative;
}

@keyframes pop {
    0% {
        transform: scale(1);
        opacity: 0.5;
    }
    50% {
        transform: scale(1.2);
        opacity: 1;
    }
    100% {
        transform: scale(1);
        opacity: 0.5;
    }
}
