.platform-info-card {
    background-color: #252224 !important;
    margin-bottom: 16px;
    margin-right: 30px;
}

.platform-info-divider {
    margin-bottom: 15px !important;
    background-color: white;
}

.platform-info-text {
    color: #FFF !important;
    margin-bottom: 15px !important;
}

.platform-info-project-text {
    color: white !important;
    margin-bottom: 8px;
}

.platform-info-workspace-icon {
    color: #9ec3e1 ;
    font-size: 25px;
    margin-left: 8px;
}

@media (max-width: 1200px) {
    .platform-info-card {
        margin-left: 2.5vh;
    }
}