.create-secret-encrypt-info {
    min-width: 450px;
    color: white;
}

.create-secret-form {
    width: 100%;
}

.create-secret-form .MuiSelect-select {
    color: #fff;
}

.create-secret-select {
    color: #ffffff !important;
    background-color: #2f2f2f !important;
}
