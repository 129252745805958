.checklist-paper {
    border: 1px solid #ccc;
    padding: 8px;
    width: 95%;
    margin-bottom: 2%;
}

.checklist-small-paper {
    border: 1px solid #ccc;
    margin-bottom: 2%
}

.checklist-items-drag-div-divider {
    margin-bottom: 15px !important;
}

.checklist-title-container {
    display: flex !important;
    align-items: center !important;
    position: relative !important;
}

.checklist-title-container:hover .checklist-drag-icon {
    visibility: visible !important;
    opacity: 1 !important;
    position: relative !important;
}

.checklist-drag-icon {
    visibility: hidden !important;
    opacity: 0 !important;
    transition: opacity 0.3s ease-in-out !important;
    position: relative !important;
    left: 0 !important;
    color: white !important;
}

.checklist-title-input {
    color: white !important;
    flex-grow: 1;
}

.checklist-button {
    margin-right: 10px !important;
}


.checklist-progress-text {
    color: white !important;
    margin-left: 10px !important;
}

.checklist-menu .MuiPaper-root {
    background-color: #333 !important;
}

.checklist-menu .MuiMenuItem-root {
    color: white !important;
}

.checklist-new-item-input {
    flex-grow: 1 !important;
    margin-right: 5px !important;
    color: white !important;
}

.checklist-linear-progress {
    margin-bottom: 5px;
}

.checklist-add-item {
    display: flex;
    align-items: center;
    margin-top: 5px;
}




.checklist-item-checked {
    opacity: 1;
  }
  
  .checklist-item-checked.dragging {
    opacity: 0.5;
  }
  .checklist-small-paper {
    transition: visibility 0.2s ease-in-out, opacity 0.2s ease-in-out;
  }
  
.checklist-delete-icon {
    color: red;
    margin-right: 8px;
}

.checklist-visibility-icon {
    color: grey;
    margin-right: 8px;
}

.checkbox-icons-div {
    display: flex;
    justify-content: flex-end;
}

.checklist-menu-item:hover {
    background-color: #1b1b1b !important;
}

@media (max-width: 900px) {
    .checklist-button {
        display: none !important;
    }
}
