.sidebar-main-box {
    display: flex !important;
    flex-direction: column !important;
    height: 100% !important;
}

.sidebar-drawer {
    width: 240px !important;
    flex-shrink: 0 !important;
}

.sidebar-drawer .MuiDrawer-paper {
    width: 240px;
    background-color: #252224 !important;
    color: #0063B2 !important;
    border-color: #A3989E !important;
}

.sidebar-toolbar {
    margin-left: -20px;
}

.sidebar-text {
    color: #C0C0C0 !important;
}

.sidebar-image {
    width: 100%;
}

.sidebar-divider {
    background-color: #A3989E !important;
}

.sidebar-project-list-box {
    overflow: auto !important;
    display: flex !important;
    flex-direction: column !important;
}

@media (max-width: 600px) {
    .sidebar-drawer .MuiDrawer-paper {
        width: 50%;
    }
}

@media (max-width: 400px) {
    .sidebar-drawer .MuiDrawer-paper {
        width: 70%;
    }
}