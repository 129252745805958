.create-resource-form {
    width: 100%;
}

.create-resource-form .MuiSelect-select {
    color: #fff;
}

.create-resource-select {
    color: #ffffff !important;
    background-color: #2f2f2f !important;
}