.vacation-approval-calendar-tooltip {
    background-color: #2f2f2f;
    padding: 1rem;
    border-radius: 8px;
    margin-top: 4px;
    margin-bottom: 4px;
    height: auto;
    align-self: flex-start;
    flex-grow: 1
}

.vacation-approval-user-info {
    color: #cfcfcf;
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
}

.vacation-approval-loading {
    display: flex;
    justify-content: center;
    margin-top: 16px;
}

.vacation-approval-requests-box {
    max-height: 400px;
    overflow-y: auto;
}

.vacation-approval-vacation-request {
    padding: 2px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
}

.vacation-approval-warning-border {
    border: 4px solid transparent;
    border-image: linear-gradient(45deg, yellow 6.25%, black 6.25%, black 12.5%, yellow 12.5%, yellow 18.75%, black 18.75%, black 25%, yellow 25%, yellow 31.25%, black 31.25%, black 37.5%, yellow 37.5%, yellow 43.75%, black 43.75%, black 50%, yellow 50%, yellow 56.25%, black 56.25%, black 62.5%, yellow 62.5%, yellow 68.75%, black 68.75%, black 75%, yellow 75%, yellow 81.25%, black 81.25%, black 87.5%, yellow 87.5%, yellow 93.75%, black 93.75%, black 100%);
    border-image-slice: 1;
    border-radius: 4px;
    padding: 2px;
}

.vacation-approval-date-color {
    color: #ff4081;
}

.vacation-approval-description {
    display: flex;
    align-items: center;
}

.vacation-approval-description-icon {
    color: #ffffff;
    border: 1px dashed #ffffff;
    border-radius: 4px;
    padding: 2px;
    margin-right: 8px;
}

.vacation-approval-visibility {
    margin-left: 5px;
    padding: 2px 4px;
    border-radius: 4px;
}

.vacation-approval-visibility-public {
    color: lightgreen;
    background-color: darkgreen;
}

.vacation-approval-visibility-private {
    color: tomato;
    background-color: darkred;
}

.vacation-approval-calendar-icon {
    color: #ffffff;
    margin-left: 8px;
    cursor: pointer;
}

.vacation-approval-no-deduct-box {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.vacation-approval-flex {
    display: flex;
}

.vacation-approval-warning-margin-right {
    margin-right: 2px;
    color: #ffa726;
}

.vacation-approval-warning-margin-left {
    margin-left: 2px;
    color: #ffa726;
}

.vacation-approval-actions {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 20px;
}

.vacation-approval-actions-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1px;

}

.vacation-approval-actions-box-2 {
    width: 30px;
    height: 30px;
    display: inline-block;
    background-size: cover;
    cursor: pointer;
}

.vacation-approval-refute {
    background-color: #ff9800 !important;
    color: #ffffff !important;
    margin-top: 8px !important;
}

.vacation-approval-buttons-box {
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;
}


.vacation-approval-main-style {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 750px;
    max-height: 90vh;
    background-color: #1c1c1c;
    border: 2px solid #2f2f2f;
    box-shadow: 0 4px 24px rgba(0, 0, 0, 0.1);
    padding: 24px;
    overflow-y: auto;
}

.vacation-approval-cancel-button {
    padding: 10px !important;
}

.vacation-approval-flex-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@media (max-width: 1000px) {
    .vacation-approval-main-style {
        width: 70%;
    }
}

@media (max-width: 750px) {
    .vacation-approval-flex-container {
        flex-direction: column;
        align-items: flex-start;
    }

    .vacation-approval-actions {
        width: 100%;
        margin-top: 10px;
    }
}