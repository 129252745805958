/* Login.css */

body {
    margin: 0;
    padding: 0;
    font-family: 'Inter', sans-serif;
}

.forgot-password-background {
    background-image: url('../../assets/img/login/bg.png');
    width: 100vw;
    height: 100vh;
    position: fixed;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    top: 0;
}

.forgot-password-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
}


.forgot-password-header {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    color: #78909c;
    font-size: 0.8em;
    background: white;
    width: 30%;
    padding: 5px;
    border-left: 1px solid transparent;
    border-radius: 1px;
    border-image: linear-gradient(90deg, #f5511a, #ff9d0a, #07ab4c, #0771ba) 1;
    animation: borderAnimation 2s infinite;
    text-align: center;
    position: relative;
    overflow: hidden;
    padding-top: 10px;
}

/* Create the tilted effect */
.forgot-password-header::before {
    content: '';
    position: absolute;
    top: 0;
    right: 20px; /* Align with the edge */
    bottom: 0;
    border-right: 1px solid transparent; /* Border width and initial color */
    border-image: linear-gradient(90deg, #f5511a, #ff9d0a, #07ab4c, #0771ba) 1;
    transform: skewX(45deg); /* Tilt effect */
    transform-origin: right; /* Keep the skew on the right side */
    z-index: 1; /* Ensure it stays above the background */
}

/* Create the appearance of the top border stopping */
.forgot-password-header::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 40px; /* Span the full width */
    height: 1px; /* Same height as the top border */
    background: linear-gradient(90deg, #f5511a, #ff9d0a, #07ab4c, #0771ba);
    z-index: 0; /* Ensure it stays behind the tilted effect */
}

.forgot-password-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 2%;
    color: white;
}

.forgot-password-logo img {
    margin-top: 5%;
    width: 60%;
}

.forgot-password-form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 30px;
    width: 100%;
    background: white;
    border: 1px solid transparent; /* Border width and initial color */
    border-radius: 1px; /* Rounded corners, adjust as needed */
    border-image: linear-gradient(90deg, #f5511a, #ff9d0a, #07ab4c, #0771ba) 1;
    animation: borderAnimation 2s infinite;
    border-top: 0px solid transparent; /* Border width and initial color */
    position: relative; /* Necessary for pseudo-element positioning */
}

/* Create the effect of removing the top-left border */
.forgot-password-form-container::before {
    content: '';
    position: absolute;
    width: 70.2%;
    top:0;
    right:0;
    border-radius: 1px; /* Rounded corners, adjust as needed */
    border-image: linear-gradient(90deg, #f5511a, #ff9d0a, #07ab4c, #0771ba) 1;
    border-top: 1px solid transparent; /* Border width and initial color */
    animation: borderAnimation 2s infinite;
}

.forgot-password-text-center {
    text-align: center;
}

.error-message {
    color: red;
    font-weight: normal;
    font-size: 1em;
}

.forgot-password-custom-button {
    width: 100%;
    height: 50px;
    border-radius: 0 !important;
    background-color: #16cd62 !important;
    color: white !important;
    transition: background 0.5s ease;
}

.forgot-password-form {
    width: 70%;
    margin-bottom: 2%;
}

.custom-button:hover {
    background: linear-gradient(90deg, #f5511a, #ff9d0a, #07ab4c, #0771ba);
    background-size: 400% 400%;
    animation: rainbowGradient 1.5s infinite alternate;
}

.forgot-password-error {
    width: 100% !important;
}

@keyframes rainbowGradient {
    0% {
        background-position: 0% 50%;
    }
    100% {
        background-position: 100% 50%;
    }
}

@keyframes borderAnimation {
    0% {
        border-image-source: linear-gradient(90deg, #f5511a, #ff9d0a, #07ab4c, #0771ba);
        border-image-slice: 1;
    }
    50% {
        border-image-source: linear-gradient(90deg, #0771ba, #07ab4c, #ff9d0a, #f5511a);
        border-image-slice: 1;
    }
    100% {
        border-image-source: linear-gradient(90deg, #f5511a, #ff9d0a, #07ab4c, #0771ba);
        border-image-slice: 1;
    }
}

.forgot-password-footer-links {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.forgot-password-footer-link {
    color: #78909c;
    font-weight: normal;
    font-size: 12px;
    margin: 5px 0;
}

.forgot-password-footer-link a {
    color: #19b5ff;
    text-decoration: none;
}

.forgot-password-back {
    cursor: pointer;
}

.forgot-password-back-icon {
    width: 5%;
    height: 100%;
    border-radius: 4px !important;
    padding: 0 5% !important;
}

.forgot-password-back-icon-margin {
    margin-left: 10px;
}




@media (min-width: 320px) {
    .forgot-password-header {
        font-size: 0.8em;
        width: 40%;
        padding-right: 20px;
    }

    .forgot-password-logo {
        font-size: 2.5em;
    }

    .forgot-password-card {
        width: 95%;
    }

    /* Create the effect of removing the top-left border */
    .forgot-password-form-container::before {
        width: 63.5%;
    }

    .forgot-password-header::before {
        right: 23px; /* Align with the edge */
        transform: skewX(43deg); /* Tilt effect */
    }

    .forgot-password-footer-links {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}


@media (min-width: 375px) {

    /* Create the effect of removing the top-left border */
    .forgot-password-form-container::before {
        width: 62.5%;
    }
}


@media (min-width: 767px) {
    .forgot-password-header {
        font-size: 0.8em;
        width: 30%;
    }

    .forgot-password-logo {
        font-size: 2.5em;
    }

    .forgot-password-card {
        width: 90%;
    }

    /* Create the effect of removing the top-left border */
    .forgot-password-form-container::before {
        width: 71.15%;
    }

    .forgot-password-header::before {
        right: 23px; /* Align with the edge */
    }

    .forgot-password-footer-links {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }


}

@media (min-width: 992px) {
    .forgot-password-header {
        font-size: 1em;
    }

    .forgot-password-logo {
        font-size: 3em;
    }

    .forgot-password-card {
        width: 60%;
    }

    .forgot-password-form-container::before {
        width: 70.2%;
    }

    .forgot-password-header::before {
        right: 20px; /* Align with the edge */
    }
}

@media (min-width: 1440px) {

    .forgot-password-card {
        width: 70%;
    }
}

@media (min-width: 2560px) {

    .forgot-password-card {
        width: 60%;
    }
}
