
/* Dialog content specific styling */
.download-warning-dialog-content {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    font-size: 1.1rem;
    color: white;
}
