.working-hours-card {
    background-color: #252224 !important;
    margin-bottom: 16px;
}

.working-hours-grid-item {
    display: flex;
    align-items: center;
}

.working-hours-text {
    display: flex;
    align-items: center;
    justify-content: center;
}

.working-hours-time-input {
    color: white !important;
}

.working-hours-time-input input[type="time"]::-webkit-calendar-picker-indicator {
    filter: invert(1);
}

.working-hours-delete-icon {
    color: red !important;
}

.working-hours-delete-icon-disabled {
    color: grey !important;
}

.working-hours-add-slot,
.working-hours-save-button {
    margin-top: 16px;
    justify-content: space-between;
    display: flex;
}

.total-working-hours {
    color: #67b5d9 !important;
}

.working-hours-slot {
    margin-top: 8px !important;
}

.working-hours-tooltip-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.working-hours-tooltip-box {
    background-color: #67b5d9;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
}

.working-hours-weekdays {
    display: flex;
    gap: 8px;
    overflow-x: auto;
}

.working-hours-weekdays-button-selected {
    color: white !important;
    background-color: #a3989e !important;
    border-color: black !important;
}

.working-hours-weekdays-button {
    color: #000 !important;
    background-color: #3d393b !important;
    border-color: black !important;
}

.working-hours-delete-icon-margin {
    margin-top: 10px;
    margin-left: 10px;
}

.working-hours-time-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.working-hours-mobile-view {
    padding: 10px;
}

.working-hours-mobile-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 1em;
}

.working-hours-day {
    text-align: left;
}

.working-hours-day-text {
    font-weight: bold;
    color: white;
}

.working-hours-time {
    text-align: center;
}

.working-hours-time-text {
    color: #67b5d9;
    font-size: 0.9em;
    white-space: pre-line;
}

.working-hours-workspace-job {
    margin-top: 16px;
    margin-bottom: 8px;
    display: flex;
    align-content: center;
    align-items: center;
    flex-direction: column;
}

@media (max-width: 450px) {
    .working-hours-add-slot {
        flex-direction: column;
        align-items: flex-start;
    }

    .working-hours-time-wrapper {
        flex-wrap: wrap;
    }

    .working-hours-save-button {
        margin-top: 1em !important;
    }

    .working-hours-delete-icon-margin {
        width: 100%;
    }
}

@media (max-width: 600px) {
    .working-hours-grid {
        display: none;
    }


    .working-hours-mobile-view {
        display: block;
    }
}

@media (min-width: 601px) {
    .working-hours-mobile-view {
        display: none;
    }

    .working-hours-grid {
        display: block;
    }
}