.online-checker-box {
    display: flex;
    justify-content: center;
    align-items: center
}

.online-checker-text-content {
    display: flex;
    align-items: center;
}

.online-checker-text-margin {
    margin-right: 12px !important;
}