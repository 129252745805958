.cookies-container {
    background: black !important;
    text-align: center;
    font-size: 13px;
    font-weight: normal;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.cookies-button {
    color: #4e503b;
    font-size: 13px;
    align-self: center;
}
