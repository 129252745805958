.action-buttons-settings {
    margin-bottom: 35px !important;
    margin-top: 20px !important;
    display: flex;
    justify-content: space-between;
}

.tabs-settings {
    width: 100%;
    margin-bottom: 25px;
    overflow-x: auto;
}

.overview-settings {
    display: flex;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 25px !important;
}

.overview-icon-boxes-settings {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 8px;
    min-width: 155px;
    margin-right: 10px;
    background-color: #252225 !important;
}

.overview-content-boxes-settings {
    display: flex;
    flex-direction: column !important;
    justify-content: flex-start;
    margin-bottom: 25px !important;
}

.overview-boxes-paper-settings, .subscription-boxes-settings {
    padding: 16px;
    margin-bottom: 16px !important;
    background-color: #252225 !important;
}

.plans-boxes-settings {
    padding: 16px;
    margin-bottom: 16px !important;
    text-align: center;
    background-color: #252225 !important;
}

.subscription-transactions-settings {
    padding: 8px;
    margin-bottom: 8px !important;
    background-color: #252225 !important;
}

@media (max-width: 480px) {
    .overview-settings {
        flex-direction: column !important;
    }

    .overview-icon-boxes-settings {
        margin-top: 0.5em;
        width: 100%;
    }
}