.cover-picker-margin-top {
    margin-top: 8px !important;
}

.cover-picker-dialog-title {
    display: flex;
    justify-content: center;
}

.cover-picker-content-box {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 300px;
}

.cover-picker-text {
    width: 100%;
}

.cover-picker-img-box {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.cover-picker-img-dimensions {
    max-height: 200px !important;
    max-width: 340px !important;
}