.maintenance-header{
    display: flex;
    flex-direction: row;
    width: 80%;
    justify-content: space-around;
}
.maintenance-text{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.maintenance-bar{
    width: 200px;
    height: 350px;
    background-size:contain;
    background-position: center;
    margin: 0 -50px;
    animation: pop 1.2s infinite;
    position: relative
}

.maintenance-container{
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    overflow: hidden;
}


@media (max-width: 768px) {
    .maintenance-header{
        flex-direction: column;
    }
}

@media (max-width: 425px) {
    .maintenance-header{
        flex-direction: column;
    }
    .maintenance-bar{
        width: 100px;
        height: 150px;
        background-size:contain;
        background-position: center;
        margin: 0 -30px;
        animation: pop 1.2s infinite;
        position: relative
    }
}
