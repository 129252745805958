.tab-panel-support {
    padding: 24px
}

.content-support {
    width: 100%;
    margin-bottom: 25px;
    overflow-x: auto;
}

.tabs-support {
    display: flex;
    justify-content: center;
}

.loading-support {
    display: flex;
    justify-content: center;
    margin-top: 24px;
}

.modal-ticket-support {
    display: flex !important;
    flex-direction: row;
    width: 90% !important;
    margin: 40px auto auto !important;
    background-color: #242224 !important;
    color: #a1989e !important;
    box-shadow: 0 0 24px rgba(0, 0, 0, 0.12) !important;
    padding: 32px !important;
    border-radius: 8px !important;
}

.modal-ticket-chat-support {
    flex: 5 !important;
    padding-right: 24px !important;
}

.modal-ticket-divider-support {
    margin-top: 16px !important;
    margin-bottom: 16px !important;
    border-color: #a1989e !important;
}

.modal-ticket-chat-content-support {
    max-height: 40vh;
    overflow-y: auto;
}

.modal-ticket-margin-bottom-support {
    margin-bottom: 16px !important;
}

.modal-ticket-message-support {
    margin-top: 8px !important;
    display: flex !important;
}

.modal-ticket-attachment-icon-support {
    color: #a1989e !important;
    margin-top: 8px !important;
}

.modal-ticket-margin-top-support {
    margin-top: 16px !important;
}

.modal-ticket-button-box-support {
    display: flex;
    justify-content: space-between;
}

.modal-ticket-attach-file-button-support {
    margin-top: 16px;
    display: flex;
    align-items: center;
}

.modal-ticket-attachments-support {
    display: flex;
    align-items: center;
    margin-left: 16px;
    max-width: 200px;
}

.modal-ticket-attachments-text-support {
    color: #a1989e;
    margin-right: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 150px;
}

.modal-ticket-send-message-button-text-support {
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;
}

.modal-ticket-info-support {
    display: flex;
    margin-top: 16px;
}

.modal-ticket-info-text-support {
    color: #ffeb3b !important;
    margin-bottom: 8px !important;
}

.modal-ticket-details-support {
    flex: 2;
    padding-left: 24px;
}

.modal-ticket-details-text-support {
    overflow-wrap: anywhere;
}


@media (max-width: 1200px) {
    .modal-ticket-support {
        flex-direction: column-reverse;
        overflow-y: auto;
        max-height: 90vh;
    }

    .modal-ticket-chat-support {
        padding-right: 0 !important;
    }

    .modal-ticket-support::-webkit-scrollbar {
        display: none; /* For Chrome, Safari, and Opera */
    }

    .modal-ticket-details-support {
        padding-left: 0;
    }

}