.purchase-plan-modal {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}

.purchase-plan-box {
    width: 80%;
    max-width: 800px;
    padding: 32px;
    border-radius: 8px;
    overflow: auto;
    max-height: 80vh;
    background-color: #252225 !important;
}

.purchase-plan-box-padding {
    padding: 16px;
}

.purchase-plan-paper {
    padding: 16px;
    margin-bottom: 16px;
    background-color: #4a4a4e !important;
}

.purchase-plan-grid-item {
    padding: 16px;
    background-color: #4a4a4e !important;
}

.purchase-plan-disabled {
    background-color: lightgrey;
}

.purchase-plan-extra-actions {
    display: flex;
    align-items: center;
    margin-top: 8px;
}

.purchase-plan-extra-counter {
    margin: 0 8px !important;
}

.purchase-plan-price-info {
    color: red;
    margin-top: 16px;
}

.purchase-plan-summary {
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 16px;
    padding: 16px;
}

.purchase-plan-summary-paper {
    padding: 16px;
    display: flex;
    align-items: center;
    gap: 8px;
    background-color: #4a4a4e !important;
}

.purchase-plan-summary-paper-group {
    background-color: #ff9d00 !important;
}

.purchase-plan-summary-paper-storage {
    background-color: #02ae4c !important;
}

.purchase-plan-summary-paper-price {
    background-color: #0576c7 !important;
}

.purchase-plan-summary-icon-group {
    color: #ffc500;
}

.purchase-plan-summary-icon-storage {
    color: #00e777;
}

.purchase-plan-summary-icon-price {
    color: #64b6f6;
}

.purchase-plan-black-txt {
    color: black !important;
}

.purchase-plan-no-features {
    margin-top: 8px;
}

.purchase-plan-confirm-buttons {
    display: flex;
    justify-content: space-between;
}

.purchase-plan-button {
    margin-top: 16px;
}