.overarching-box-archive {
    transition: width 300ms ease-in-out;
    margin-bottom: 8px;
    border-radius: 12px;
    max-width: 300px;
    margin-left: 25px;
    margin-right: 25px;
    overflow-x: hidden;
    height: fit-content;
    min-height: 150px;
    max-height: 60vh;
    width: 300px;
}

.deleted-box-archive {
    background-color: #2A3D74;
}

.default-box-archive {
    background-color: #C0C0C0;
}

.card-content-archive {
    transition: max-height 300ms ease-in-out, padding 300ms ease-in-out;
    overflow: hidden;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    background-color: inherit;
    color: inherit;
    padding: 16px;
    max-height: 100vh;
}

.box-archive {
    transition: width 300ms ease-in-out;
    margin-bottom: 2px;
    border-radius: 3px;
    max-width: 270px;
    overflow-x: hidden;
    max-height: calc(70vh - 120px);
    width: 300px;
    overflow-y: auto;
}

.typography-h5-archive {
    padding-bottom: 20px;
    white-space: nowrap;
}

.box-list-title-archive {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: inherit;
}

.box-text-overflow-archive {
    max-width: 90%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.typography-title-archive {
    max-width: 100% !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    color: #FFFFFF !important;
    font-size: 12px !important;
}

.lock-icon-archive {
    font-size: 18px;
    color: #FFFFFF;
}

.task-archive {
    border: 1px solid #ccc;
    padding: 8px;
    background-color: #D6D4D4;
    margin: 1% 1% 5%;
}

.ellipsis-box-archive {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #C0C0C0;
}

.custom-divider-archive {
    margin: 0.5rem 0;
    border-color: #3A3A3A;
}

.custom-box-archive {
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #C0C0C0;
}

.custom-container-archive {
    height: calc(100vh - 120px);
    overflow: auto;
    display: flex;
    flex-direction: column;
}

.container-archive {
    margin-bottom: 20px;
}

.custom-card-archive {
    background-color: #252224 !important;
    padding: 0;
}

.custom-card-content-archive {
    padding-bottom: 16px;
}

.centered-flex-archive {
    display: flex;
    align-items: center;
    justify-content: center;
}

.flex-row-archive {
    display: flex;
    align-items: center;
    margin-right: 20px;
}

.circle-archive {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 8px;
}

.circle-unarchived-archive {
    background-color: #C0C0C0;
}

.circle-archived-archive {
    background-color: #2A3D74;
}

.typography-unarchived-archive,
.typography-archived-archive {
    color: #C0C0C0;
    text-align: center;
}

.typography-unarchived-archive {
    margin-right: 10px !important;
}

.flex-container-archive {
    flex: 1 1 auto;
    overflow-x: auto;
    overflow-y: hidden;
    display: flex;
}

.my-lists-class-archive {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    margin-top: 10px;
}

.task-title-archive {
    word-break: break-word;
    overflow-wrap: break-word;
}

.menu-paper-archive {
    background-color: #252224;
    color: #C0C0C0;
    max-width: 230px;
}

.list-menu-paper-archive {
    background-color: #252224;
    color: #C0C0C0;
    max-width: 350px;
}


@media (min-width: 600px) { /* sm */
    .typography-title-archive {
        font-size: 14px !important;
    }
}

@media (min-width: 960px) { /* md */
    .typography-title-archive {
        font-size: 16px !important;
    }
}

@media (min-width: 1280px) { /* lg */
    .typography-title-archive {
        font-size: 18px !important;
    }
}
