.comments-margin {
    margin-bottom: 20px;
}

.comments-title {
    font-weight: bold;
}

.comments-divider {
    margin-bottom: 10px;
}

.comments-box {
    margin-bottom: 20px;
}

.comments-paper {
    padding: 16px;
    margin-bottom: 20px;
    margin-right: 20px;
    display: flex;
    align-items: center;
}

.comments-flex {
    display: flex;
    align-items: center;
}

.comments-reply-button {
    margin-left: 40px;
    margin-top: 10px;
}

.comments-reply-box {
    margin-left: 40px;
    margin-top: 10px;
    display: flex;
    align-items: center;
}

.comments-mention-input {
    background-color: #3F4174;
}

.comments-avatar {
    width: 30px;
    height: 30px;
    margin-right: 8px;
}

.comments-avatar-margin {
    margin-right: 10px;
}

.comments-comment-padding {
    padding: 10px;
}

.comments-reply-padding {
    padding: 10px 0;
}

.comments-subtitle {
    max-width: 250px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.comments-icon-button {
    margin-left: 10px;
}


.mentions {
    width: 90%;
}

.mentions--multiLine .mentions__control {
    font-size: 14px;
    padding: 12px;
    background-color: #1e1e1e;
    border-radius: 4px;
    color: #FFF;
    height: 60px;
}

.mentions--multiLine .mentions__highlighter {
    overflow: hidden;
}

.mentions--multiLine .mentions__input {
    margin: 0;
    color: #FFF;
    min-height: 60px;
    padding: 10px;
}

.mentions__suggestions__list {
    background-color: #333;
    border: 1px solid #222;
    font-size: 14px;
    color: white;
    position: absolute;
    top: 20px;
}

.mentions__suggestions__item {
    padding: 5px 15px;
    border-bottom: 1px solid #222;
    color: white;
}

.mentions__suggestions__item--focused {
    background-color: #222;
    color: white;
}


.comments-comment-text {
    overflow-wrap: anywhere;
}