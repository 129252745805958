
@font-face {
    font-family: 'Poppins';
    src: url('../assets/fonts/Poppins-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Poppins';
    src: url('../assets/fonts/Poppins-Medium.ttf') format('truetype');
    font-weight: medium;
    font-style: medium;
  }

  @font-face {
    font-family: 'Poppins';
    src: url('../assets/fonts/Poppins-SemiBold.ttf') format('truetype');
    font-weight: semibold;
    font-style: semibold;
  }

  @font-face {
    font-family: 'Myriad Pro';
    src: url('../assets/fonts/MYRIADPRO-REGULAR.OTF') format('opentype');
    font-weight: normal;
    font-style: normal;
  }

.white-txt-color {
    color: #FFF !important;
}

.project-txt-color {
    color: #C0C0C0 !important;
}

.no-display {
    display: none !important;
}

.button-light-bg {
    background-color: #252225 !important;
    color: #eceff1 !important;
}

.button-light-bg:hover {
    background-color: #a3989e !important;
    color: #252225 !important;
}

.button-dark-bg {
    color: #eceff1 !important;
    background-color: #4a4a4e !important;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}

.button-dark-bg:hover {
    color: #eceff1 !important;
    background-color: #38383b !important;
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.3);
}

.button-dark-bg:disabled {
    color: #b0bec5 !important;
    background-color: #414142 !important;
    box-shadow: none !important;
    cursor: not-allowed !important;
}

.accept-button {
    background-color: #4caf50 !important;
    color: #ffffff !important;
    border-radius: 8px !important;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2) !important;
    transition: background-color 0.3s, transform 0.3s !important;
}

.accept-button:disabled {
    background-color: grey !important;
}

.accept-button:hover {
    background-color: #45a049 !important;
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.3) !important;
}

.decline-button {
    background-color: #f44336 !important;
    color: #ffffff !important;
    border-radius: 8px !important;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2) !important;
    transition: background-color 0.3s, transform 0.3s !important;
    margin-right: 10px !important;
}

.decline-button:disabled {
    background-color: grey !important;
}

.decline-button:hover {
    background-color: #d32f2f !important;
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.3) !important;
}

.buttons-flex {
    display: flex !important;
    justify-content: flex-end !important;
    flex-direction: row !important;
}

.image-border {
    border: 2px solid black !important;
    border-radius: 12px !important;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2) !important;
    transition: transform 0.3s ease, box-shadow 0.3s ease !important;
}

.image-rectangle {
    margin-top: 10px;
    max-width: 100%;
    max-height: 200px
}

.image-reset-button {
    background-color: #f44336;
    color: #ffffff; /* White text color */
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease-in-out;
}

.image-reset-button:hover {
    background-color: #d32f2f;
}

.preview-image-size {
    width: 100%;
    height: 100%;
    object-fit: cover !important;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 12px;
}

.image-container {
    position: relative;
    height: 290px;
    width: 90%;
    max-width: 590px;
}

.image-container-workspace {
    position: relative;
    height: 300px;
    width: 90%;
    max-width: 300px;
}

.image-container-list-header {
    position: relative;
    width: 90%;
    max-width: 350px;
    height: 150px;
}


.image-container:hover .overlay, .image-container-workspace:hover .overlay, .image-container-list-header:hover .overlay {
    opacity: 1;
}

.modal-background-color .MuiPaper-root {
    background-color: #252224 !important;
}

.MuiMenuItem-root {
    white-space: normal !important;
}


@media (max-width: 850px) {
    .image-container-workspace {
        width: 38%;
        max-width: none;
        height: 38vw;
    }
}

@media (max-width: 500px) {
    .image-container-workspace {
    width: 60%;
    max-width: none;
    height: 60vw;
}
}


/* Change font size for smaller screens
@media (max-width: 1200px) {
    .MuiTypography-h5 {
        font-size: 2.5rem !important;
    }

    .MuiTypography-h6 {
        font-size: 2rem !important;
    }

    .MuiTypography-body1 {
        font-size: 1.45rem !important;
    }

    .MuiTypography-body2 {
        font-size: 1.375rem !important;
    }

    .MuiTypography-subtitle1 {
        font-size: 1.5rem !important;
    }

    .MuiTypography-subtitle2 {
        font-size: 1.25rem !important;
    }
}*/