.main-box-padding-settings {
    padding: 20px !important;
}

.select-language-settings {
    margin-bottom: 10px !important;
    color: #C0C0C0 !important;
    font-weight: bold !important;
}

.select-language-button-settings {
    width: 200px !important;
    display: flex !important;
    justify-content: center !important;
    background-color: #2a2a2a !important;
    color: white !important;
    padding: 12px !important;
    text-transform: none !important;
}

.select-language-button-settings:hover {
    background-color: #3d3d3d !important;
}

.selected-language-settings {
    font-weight: bold !important;
}

.selected-language-ext-settings {
    color: #ccc !important;
}

.language-selection-menu-settings .MuiPaper-root {
    background-color: #444 !important;
    color: white !important;
    min-width: 200px !important;
}

.language-item-menu-settings {
    padding: 10px !important;
    color: #fff !important;
}

.language-item-menu-settings:hover {
    background-color: #555 !important;
}

.language-selection-menu-content-settings {
    display: flex !important;
    justify-content: center !important;
    width: 100% !important;
}

.language-name-settings {
    margin-left: 10px !important;
}