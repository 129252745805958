.holiday-modal-main-style {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 600px;
    background-color: #1c1c1c;
    color: #fff;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.2);
    padding: 16px;
    border-radius: 10px;
}

.holiday-modal-title-button {
    display: flex;
    flex-direction: row;
    justify-content: space-between
}

.holiday-modal-loading {
    display: flex;
    justify-content: center;
    margin-top: 16px;
}

.holiday-modal-textfield {
    width: 100% !important;
    background-color: #333;
    color: white;
}

.holiday-modal-textfield .MuiInputBase-input {
    color: white;
}

.holiday-modal-create-button {
    margin-top: 16px !important;
}

.holiday-modal-pagination {
    margin-top: 16px !important;
    display: flex !important;
    justify-content: center !important;
}

.holiday-modal-pagination .MuiPaginationItem-root {
    color: #F5F5F5;
}

.holiday-modal-title {
    color: #fff !important;
    font-size: 1.25rem !important;
}

.holiday-modal-select-menu {
    background-color: #2f2f2f !important;
    color: white !important;
}

@media (max-width: 1000px) {
    .holiday-modal-main-style {
        width: 75%;
    }
}

@media (max-width: 600px) {
    .holiday-modal-title-button {
        flex-direction: column;
        align-items: center;
    }
}