.event-details-text-color {
    color: #C0C0C0 !important;
}

.event-details-textfield {
    background-color: #333 !important;
    color: #fff !important;
}

.event-details-main-styles {
    background-color: #4c4c4c !important;
    color: #ffcc00 !important;
    text-align: center !important;
}

.event-details-content-dialogue {
    background-color: #2E2D2D !important;
    color: #E0E0E0 !important;
}

.event-details-description {
    margin-bottom: 16px !important;
}

.event-details-edit {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    padding: 16px !important;
    border-radius: 8px !important;
    box-shadow: 0 2px 1px -1px rgba(0,0,0,0.2), 0 1px 1px 0 rgba(0,0,0,0.14), 0 1px 3px 0 rgba(0,0,0,0.12) !important;
    width: 100% !important;
}

.event-details-edit-content {
    display: flex !important;
    align-items: center !important;
    margin-top: 8px !important;
}

.event-details-event-info-margin {
    margin-right: 8px !important;
}

.event-details-access-info-margin {
    margin-right: 8px !important;
    margin-left: 16px !important;
}

.event-details-collabs-grid {
    display: flex !important;
    justify-content: space-evenly !important;
    align-items: center !important;
}

.event-details-divider {
    background-color: #A3989E !important;
    margin-right: 20px !important;
    margin-left: 10px !important;
}

.event-details-add-collabs {
    color: #C0C0C0 !important;
    display: flex !important;
    align-items: center !important;
}

.event-details-collab-list {
    max-height: 200px !important;
    overflow-y: auto !important;
    margin-bottom: 16px !important;
}

.event-details-remove-collab {
    color: red !important;
}

.event-details-add-collab {
    color: green !important;
}

.event-details-no-collabs {
    color: #ffcc00 !important;
    padding-left: 16px !important;
}

