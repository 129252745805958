.common-avatar-group .MuiAvatar-root {
    cursor: pointer !important;
    border: 2px solid #000000 !important;
}

.common-avatar-group .MuiAvatar-root:hover {
    border-color: #FFD700 !important;
}

.common-avatar-users {
    display: flex;
    align-items: center;
    margin-right: -10px;
}

@media (max-width: 850px) {
    .common-avatar-hide  {
        display: none !important;
    }
}