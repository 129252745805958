.view-events-main-styles {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    max-height: 80vh;
    overflow-y: auto;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.24);
    padding: 32px;
}

.view-events-collab-grid {
    flex-wrap: nowrap;
}

.view-events-list-flex {
    flex-grow: 1;
}

.view-events-description {
    border: 1px dashed grey;
    margin-bottom: 8px;
    border-radius: 5px;
    background-color: #f9f9f9;
}

.view-events-collab-tooltip {
    min-width: 200px;
}

.view-events-tooltip-avatar {
    width: 40px !important;
    height: 40px !important;
}

.view-events-avatar {
    width: 24px !important;
    height: 24px !important;
}

.view-events-title-padding {
    padding-top: 4px !important;
}

.view-events-description-padding {
    padding-top: 2px !important;
}

.view-events-content-grid {
    justify-content: space-between !important;
    align-items: center !important;
}