.input-label-edit-project .Mui-focused, .form-control-edit-project .MuiInput-underline:after {
    color: black !important;
}

.form-control-edit-project {
    width: 100%;
    background-color: transparent !important;
}

.form-control-edit-project .MuiInput-underline:before, .input-label-edit-project, .form-control-edit-project .MuiSvgIcon-root,
.select-menu-edit-project .MuiMenuItem-root{
    color: #fff !important;
}

.form-control-edit-project .MuiSelect-select {
    color: #fff !important;
    background-color: transparent !important
}

.select-menu-edit-project {
    background-color: #333 !important;
}

.current-stat-edit-project {
    margin-top: 8px !important;
}