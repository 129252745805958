.fc-day-past-calendar {
    background-color: rgba(0, 0, 0, 0.05);
    position: relative;
    z-index: 1; 
  }

.fc-event {
    z-index: 10 !important;
    position: relative;
}

.fc-daygrid-event {
    z-index: 10 !important;
}

.avatar-calendar {
    width: 24px;
    height: 24px;
    margin-right: 8px;
}

.avatar-badge-calendar {
    width: 24px;
    height: 24px;
}

.affected-users-box-items-flex-calendar {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
}

.no-country-icon-calendar {
    color: #ffcc00;
    font-size: 35px;
    margin-right: 15px;
}

.holiday-title-calendar {
    font-weight: bold !important;
    color: #ffcc00 !important;
}

.holiday-local-name-calendar {
    white-space: normal !important;
    word-break: break-word !important;
    font-weight: bold !important;
    display: flex !important;
    justify-content: center !important;
    margin-bottom: 8px !important;
    margin-left: 1px !important;
    margin-right: 1px !important;
}

.holiday-name-calendar {
    font-style: italic !important;
    display: flex !important;
    justify-content: center !important;
    margin-bottom: 8px !important;
    color: #fff !important;
}

.holiday-affected-workers-title-calendar {
    font-weight: bold !important;
    margin-bottom: 8px !important;
    text-align: center !important;
    color: #fff !important;
}

.holiday-affected-workers-avatars-box-calendar {
    display: flex !important;
    align-items: center !important;
    margin-bottom: 4px !important;
}

.holiday-affected-workers-avatars-calendar {
    width: 30px !important;
    height: 30px !important;
    margin-right: 10px !important;
}

.holiday-affect-workers-name-calendar {
    color: #ffcc00 !important;
}

.holiday-popper-props-calendar .MuiTooltip-tooltip,
.affected-users-box-calendar {
    background-color: #2E3B55;
    color: #fff;
    border-radius: 8px;
    padding: 15px;
}

.holiday-popper-props-calendar .MuiTooltip-arrow {
    color: #2E3B55;
}

.holiday-extended-local-name-calendar {
    color: #000 !important;
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    max-width: 100% !important;
}

.holiday-extended-props-icon-calendar {
    color: #ffcc00;
    font-size: 25px;
    margin-top: 5px;
    margin-bottom: 5px;
}

.title-calendar {
    display: flex !important;
    justify-content: center !important;
    margin-bottom: 20px !important;
    margin-top: 10px !important;
}

.main-content-calendar {
    text-align: center;
}

.checkboxes-calendar {
    display: flex;
    justify-content: center;
    gap: 15px;
    margin-bottom: 20px;
}

.calendar {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    margin-left: 2.5%;
    margin-right: 2.5%;
}

.flex-content-calendar {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    height: 100%;
    text-align: center;
    flex-direction: column;
}

.avatar-box-calendar {
    display: flex !important;
    align-items: center !important;
}

.flag-calendar {
    margin-right: 15px !important;
}

.extended-info-calendar {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
    height: 100%;
    text-align: center;
    flex-direction: column;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.extended-flag-calendar {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
}

@media (max-width: 850px) {
    .MuiTypography-h4 {
        font-size: 1.75rem !important;
    }

    .fc .fc-toolbar-title{
        font-size: 1.35em;
    }

    .fc .fc-button {
        font-size: 0.75em;
    }
}

@media (max-width: 500px) {
    .fc .fc-toolbar-title{
        font-size: 1em;
    }

    .fc .fc-toolbar {
        flex-direction: column;
    }

    .checkboxes-calendar {
        flex-direction: column;
        align-items: center;
    }
}