.unarchive-task-list-select, .unarchive-task-list-select .MuiSvgIcon-root {
    color: white !important;
}

.unarchive-task-list-select .MuiOutlinedInput-notchedOutline, .unarchive-task-list-select .MuiOutlinedInput-notchedOutline,
.unarchive-task-list-select .Mui-focused .MuiOutlinedInput-notchedOutline, .unarchive-task-list-select:hover .MuiOutlinedInput-notchedOutline {
    border-color: white !important;
}

.unarchive-task-menu-props {
    background-color: #252224;
    color: white;
}