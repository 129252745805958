.search-bar-item-padding {
    padding-left: 32px !important;
}

.search-bar-icon-margin {
    margin-left: auto !important;
}

.search-bar-boards-padding {
    padding-left: 16px !important;
}

.search-bar-bar {
    position: relative !important;
    width: 100% !important;
    display: flex !important;
    align-items: center !important;
}

.search-bar-results {
    position: absolute !important;
    top: 60px !important;
    width: 100% !important;
    max-height: 400px !important;
    overflow-y: auto !important;
    z-index: 1000 !important;
    padding: 10px !important;
}

.search-bar-task-text {
    overflow-wrap: anywhere;
}