.refute-message-box {
    background-color: #2f2f2f;
    padding: 1rem;
    border-radius: 8px;
    margin-bottom: 1rem;
    margin-top: 1rem;
    overflow-wrap: anywhere;
}

.refute-message-title {
    color: #ff9800 !important;
}
