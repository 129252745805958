.task-dates-main-dialog .MuiPaper-root {
    background: #252224;
    display: flex;
    text-align: -webkit-center
}

.task-dates-dialog-title {
    width: 400px ;
    color: white ;
}

.task-dates-box {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.task-dates-datetime-picker .MuiInputBase-input, .task-dates-datetime-picker .MuiSvgIcon-root,
.task-dates-dialog-actions button, .task-dates-checkbox-control, .task-dates-checkbox-label {
    color: #FFF;
}