.project-cards-card {
    background-color: #252224 !important;
    max-width: 350px !important;
}

.project-cards-media {
    height: 140px !important;
}

.project-cards-content {
    display: flex;
    justify-content: space-between;
}

.project-cards-content-text {
    max-width: 250px !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    font-weight: bold !important;
    color: #E3E3E3 !important;
}

.project-cards-more-icon {
    color: #E3E3E3;
}

.project-cards-desc {
    max-height: 40px;
    min-height: 60px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    color: #C0C0C0 !important;
}

.project-cards-actions-box {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.project-cards-star {
    color: yellow;
}

.project-cards-status {
    color: #FFF !important;
    align-content: center;
}

.project-cards-menu-list .MuiList-root {
    background-color: #333;
}