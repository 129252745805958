.checkout-modal {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}

.checkout-box {
    width: 80% !important;
    max-width: 600px !important;
    background-color: white !important;
    padding: 16px !important;
    border-radius: 8px !important;
    overflow: auto !important;
    max-height: 80vh !important;
}