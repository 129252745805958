.topbar-open {
    width: calc(100% - 240px) !important;
}

.topbar-view-events {
    color: #D6D4D4 !important;
    margin-left: 8px !important;
    font-size: 35px !important;
}

.topbar-view-events-button {
    color: burlywood !important;
}

.topbar-toolbar {
    background-color: #252224;
}

.topbar-toolbar::-webkit-scrollbar {
    display: none;
}

.topbar-dropdown-button {
    display: none;

}

.topbar-icon-button {
    width: 30px;
    height: 30px;
    rotate: -90deg;
    background-position: center;
    background-size: contain;
}

.topbar-divider {
    background-color: #A3989E;
    margin-right: 20px !important;
    margin-left: 10px !important;
}

.topbar-buttons-div {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.topbar-widget-button {
    color: #D6D4D4;
    margin-left: 5px;
    font-size: medium;
}

.topbar-project-settings .MuiPaper-root {
    width: 200px;
    background-color: #252224;
    border-color: #A3989E;
}

.topbar-add-member-button {
    margin-right: 15px;
    margin-left: 10px;
    font-size: 40px !important;
}

.topbar-members-paper .MuiPaper-root {
    width: 300px;
    background-color: #252224;
    border-color: #A3989E;
}

.topbar-members-paper .MuiAvatar-root {
    border: 2px solid #000000;
}

.topbar-members-paper .MuiAvatar-root:hover {
    border-color: #FFD700;
}

.topbar-members-content {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.topbar-members-title {
    margin-bottom: 10px !important;
}

.topbar-members-search {
    margin-bottom: 15px !important;
}

.topbar-members-no-users {
    padding-bottom: 10px !important;
}

.topbar-members-full-name {
    margin-left: 12px !important;
}

.topbar-members-list {
    margin-left: auto !important;
    margin-right: 15px !important;
}

.topbar-members-no-users-workspace {
    margin-left: 10px !important;
}

.topbar-workspace-paper .MuiPaper-root {
    width: auto;
    background-color: #252224;
    border-color: #A3989E;
}

.topbar-new-workspace-member {
    margin-bottom: 10px !important;
    margin-left: 20px !important;
}

.topbar-add-workspace-member {
    margin-left: -20px !important;
    margin-right: -15px !important;
}

.topbar-workspace-workers-list {
    display: flex !important;
    align-items: center !important;
    margin-bottom: 10px !important;
    margin-left: 12px !important;
    margin-right: auto !important;
    width: 100% !important;
}

.topbar-workspace-icons {
    color: #fff !important;
    margin-right: 10% !important;
    margin-left: auto !important;
}

.topbar-notif-button {
    display: flex !important;
    align-items: center !important;
    margin-left: auto !important;
    margin-right: 0 !important;
}

.topbar-notif-button-icon {
    color: #fff !important;
    font-size: 35px !important;
}

.topbar-avatars {
    border: 2px solid #000000 !important;
}

.topbar-avatars:hover {
    border-color: #ffffff !important;
}

.topbar-badge .MuiBadge-badge {
    background-color: #44b700;
    color: #44b700;
    box-shadow: 0 0 0 2px white;
}

.topbar-badge .MuiBadge-badge::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 1px solid currentColor;
    content: "";
    animation: topbar-ripple 1.2s infinite ease-in-out;
}

.topbar-search-bar {
    margin-left: 0;
    margin-right: auto;
    width: 50%;
}

.topbar-search-bar.show {
    display: block;
}

.topbar-search-bar.hide {
    display: none;
}

.topbar-search-icon-box {
    display: none;
}

.topbar-search-icon {
    color: white !important;
    font-size: 25px !important;
}

.topbar-avatar-group {
    display: none;
}

.topbar-workspace-members-list-title {
    display: flex;
    align-items: flex-start;
}


@keyframes topbar-ripple {
    0% {
        transform: scale(.8);
        opacity: 1;
    }
    100% {
        transform: scale(2.4);
        opacity: 0;
    }
}

@media (max-width: 1000px) {
    .topbar-open {
        width: 100% !important;
    }

    .topbar-calendar-text {
        display: none;
    }

    .topbar-view-events {
        margin-left: 0 !important;
    }

    .topbar-divider {
        margin: 0 !important;
    }

    .topbar-avatar-divider-group {
        margin-right: 20px !important;
        margin-left: 10px !important;
    }
}

@media (max-width: 850px) {
    .topbar-button-text {
        display: none;
    }
    .topbar-divider {
        margin: 0 !important;
    }

    .topbar-add-member-button {
        margin-right: -10px;
        padding: 0;
        font-size: 30px !important;
    }

    .topbar-notif-button-icon {
        font-size: 30px !important;
    }

    .topbar-widget-button {
        margin-left: 0;
        font-size: 25px !important;
    }

    .topbar-avatar-divider-group {
        margin: 0 !important;
    }
}

@media (max-width: 700px) {
    .topbar-search-icon-box {
        display: block;
    }

    .topbar-dropdown-button {
        display: flex;
    }

    .topbar-events-divider {
        display: none;
    }

    .topbar-hide-buttons {
        display: none !important;
    }

    .topbar-search-bar.show {
        position: absolute;
        top: 56px;
        left: 0;
        width: 100%;
        z-index: 999;
    }
}

@media (max-width: 450px) {
    .topbar-add-list {
        display: none;
    }
}

@media (max-width: 400px) {
    .topbar-button {
        min-width: 50px !important;
    }
}