.user-navbar-popover .MuiPopover-paper {
    background-color: #2D2D2D !important;
    color: white !important;
}

.user-navbar-container {
    width: 305px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.user-navbar-user-details {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
}

.user-navbar-full-name {
    color: white !important;
}

.user-navbar-username {
    color: #A0A0A0 !important;
}

.user-navbar-button {
    margin-bottom: 8px !important;
    background-color: #3A3A3A !important;
    color: white !important;
    width: 100%;
}

.user-navbar-button-full {
    background-color: #3A3A3A !important;
    color: white !important;
}

.user-navbar-avatar {
    margin-right: 16px;
}
