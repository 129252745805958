.icon-size-notif {
    font-size: 40px !important;
}

.container-notif {
    font-family: 'Roboto, sans-serif';
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: center;
}

.container-notif .MuiPaper-root {
    width: 100% !important;
}

.title-article-notif {
    width: 80%;
    display: flex;
    justify-content: center;
}

.title-notif {
    color: #C0C0C0 !important;
}

.divider-notif {
    background-color: #A3989E;
    margin: 2% !important;
}

.card-notif {
    background-color: gray !important;
    margin-bottom: 16px;
}

.card-content-notif {
    padding-bottom: 0;
}

.card-content-notif .MuiPaper-root {
    width: 100% !important;
}

.card-title-notif {
    color: black;
}

.icon-color-notif {
    color: inherit;
}

.mark-read-notif {
    color: darkgreen;
    font-size: 2.5rem !important;
}

.mark-unread-notif {
    color: darkred;
    font-size: 2.5rem !important;
}

.button-notif {
    font-size: 1.5rem !important;
}

.button-accept-notif {
    color: darkgreen !important;
}

.button-decline-notif {
    color: darkred !important;
}

.no-notif {
    color: #fff;
    text-align: center;
    padding-top: 10px;
}

.marked-read-txt-notif {
    display: block;
}

.marked-read-icon-notif {
    display: none !important;
}

.icons-margin-notif {
    margin-left: 5px !important;
}

@media (max-width: 1000px) {
    .title-article-notif {
        width: auto;
    }

    .container-notif {
        margin-left: 1vh;
        margin-right: 1vh;
    }
}

@media (max-width: 600px) {
    .marked-read-txt-notif {
        display: none;
    }

    .marked-read-icon-notif {
        display: block !important;
        font-size: 2rem !important;
    }

    .MuiTypography-h5 {
        font-size: 1.25rem !important;
    }

    .MuiTypography-h6 {
        font-size: 1rem !important;
    }
}