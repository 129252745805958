.personal-info-card {
    background-color: #252224 !important;
    margin-bottom: 8px;
}

.personal-info-grid-item-label {
    display: flex;
    align-items: center;
    padding-left: 0 !important;
}

.personal-info-content-margin {
    margin-top: 8px !important;
}
