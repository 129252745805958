.notification-button {
    width: 185px;
    height: 35px;
    margin-right: 25px;
    margin-top: 35px;
}

.notification-button-accept {
    color: green;
}

.notification-button-reject {
    color: red;
}

.notification-margin-left {
    margin-left: 5px;
}

.notification-unread-icon {
    margin-left: 10px;
    color: red;
}

.notification-no-notifs {
    color: #000;
    text-align: center;
    padding-top: 10px;
    margin-left: 50px;
    margin-right: 50px;
}

.notification-view-all {
    display: flex;
    width: 100%;
    padding-top: 15px;
    padding-bottom: 5px;
    justify-content: flex-end;
}

.notification-text {
    white-space: normal;
    word-wrap: break-word;
}
