.workflow-info-card {
    background-color: #252224 !important;
    margin-bottom: 16px;
}

.workflow-info-grid {
    display: flex;
    align-items: center;
    padding-left: 0 !important;
}

.workflow-info-margin {
    margin-top: 8px !important;
}