.reset-password-modal-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    max-width: 400px;
    background-color: #252224;
    border: 2px solid #000;
    padding: 2rem;
}

.reset-password-input {
    margin-top: 16px !important;
    color: white;
}

.reset-password-input .MuiOutlinedInput-root .Mui-focused fieldset {
    border-color: white;
}

.reset-password-input .MuiOutlinedInput-root fieldset {
    border-color: white;
}

.reset-password-input .MuiOutlinedInput-root:hover fieldset {
    border-color: white;
}

.reset-password-button {
    margin-top: 16px !important;
}

@media (max-width: 768px) {
    .reset-password-modal-box {
        width: 90%;
        padding: 1.5rem;
    }
}

@media (max-width: 480px) {
    .reset-password-modal-box {
        padding: 1rem;
    }
}