.sub-modal-paper {
    padding: 24px !important;
    margin: 10% auto !important;
    max-width: 600px !important;
}

.sub-modal-paper-info {
    padding: 8px !important;
    margin-top: 8px !important;
}

.sub-modal-buttons-grid {
    display: flex !important;
    justify-content: space-between !important;
}

.sub-modal-buttons-margin {
    margin-top: 16px !important;
}